import {
  Button,
  Divider,
  makeStyles,
  PlantingSummary,
  StoriesList,
  StoryDetail,
  Title,
  TreesSummary,
  Typography,
  Weather,
} from "components";
import TreesDetails from "components/treesDetails/TreesDetails";
import {
  useCustomerStories,
  usePlanting,
  usePublicStories,
  useTranslateObject,
} from "hooks";
import { Calendar, ChevronLeft, Pin } from "icons";
import { orderBy, uniqBy } from "lodash";
import { useTranslation } from "react-i18next";
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { routes } from "routes";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
  divider: {
    width: "auto",
    margin: `0 ${theme.spacing(3)}px ${theme.spacing(6)}px`,
  },
  title: {
    margin: theme.spacing(3),
  },
  description: {
    margin: theme.spacing(3),
  },
  closeLink: {
    margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  weather: {
    float: "right",
  },
  plantingInfo: {
    display: "flex",
    flexWrap: "wrap",
  },
  location: {
    display: "flex",
    color: theme.palette.text.secondary,
    alignItems: "center",
    marginTop: 5,
    marginRight: theme.spacing(2),
  },
  directionsIcon: {
    color: theme.palette.primary.main,
  },
}));

function PlantingDetails() {
  const { plantingId } = useParams<{ plantingId: string }>();
  const classes = useStyles();
  const { t } = useTranslation();
  const { translate } = useTranslateObject();
  const history = useHistory();

  const planting = usePlanting(plantingId);
  const customerStories = useCustomerStories();
  const publicStories = usePublicStories();

  const stories = orderBy(
    uniqBy([...customerStories, ...publicStories], "id"),
    "createdDate",
    "desc"
  );

  return (
    <div className={classes.root}>
      {planting ? (
        <>
          <Switch>
            <Route path={routes.plantingDetail} exact>
              <Button
                className={classes.closeLink}
                onClick={() => history.push(routes.home)}
                variant="text"
                startIcon={<ChevronLeft />}
              >
                <Typography variant="body1">{t("Back to projects")}</Typography>
              </Button>
            </Route>
            <Route path="*">
              <Button
                className={classes.closeLink}
                onClick={() =>
                  history.push(
                    generatePath(routes.plantingDetail, {
                      plantingId: planting.id,
                    })
                  )
                }
                variant="text"
                startIcon={<ChevronLeft />}
              >
                <Typography variant="body1">
                  {t(`Back to`)} <b>{planting.name}</b>
                </Typography>
              </Button>
            </Route>
          </Switch>
          <Divider className={classes.divider} />

          <Route path={routes.plantingDetail} exact>
            <Weather className={classes.weather} />
            <Title text={`Land Life Company - ${planting.name}`} />
            <div className={classes.title}>
              <Typography variant="h1">{planting.name}</Typography>
              {/* <Typography variant="subtitle1">
              {t("Start date")}: {formattedDate}
            </Typography> */}
              <div className={classes.plantingInfo}>
                <div className={classes.location}>
                  <Pin
                    showCircle={false}
                    fontSize="inherit"
                    style={{ strokeWidth: 2, marginRight: 4 }}
                  />
                  {planting.country} (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    title={t("Show in Google Maps")}
                    className={classes.directionsIcon}
                    href={`https://www.google.com/maps/search/?api=1&query=${planting.coordinates.lat},${planting.coordinates.lng}`}
                  >
                    open in Maps
                  </a>
                  )
                </div>
                <div className={classes.location}>
                  <Calendar
                    fontSize="inherit"
                    style={{ strokeWidth: 2, marginRight: 4 }}
                  />{" "}
                  {planting.plantingSeason}
                </div>
              </div>
            </div>
            {Boolean(planting.description) && (
              <Typography variant="subtitle2" className={classes.description}>
                {translate(planting, "description")}
              </Typography>
            )}

            <PlantingSummary />
            <TreesSummary treeIds={planting.treeSpecies} />
            <StoriesList
              plantingId={plantingId}
              orderByPinned={true}
              showMoreButton={true}
              stories={stories}
            />
          </Route>
          <Route path={routes.plantingTreesDetail} exact>
            <TreesDetails treeIds={planting.treeSpecies} />
          </Route>
          <Route path={routes.storyDetail} exact>
            <StoryDetail />
          </Route>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PlantingDetails;
