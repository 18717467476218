import "leaflet/dist/leaflet.css";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "routes";
import { Allocation, Plantings } from "./components";

export default function MapContent() {
  return (
    <Switch>
      <Route path={routes.plantingDetail}>
        <Allocation />
      </Route>
      <Route>
        <Plantings />
      </Route>
    </Switch>
  );
}
