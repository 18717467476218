import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

const firebaseOptions =
  process.env.REACT_APP_DEPLOYMENT === "production"
    ? {
        apiKey: "AIzaSyAwKJK6YvhsXl57_A8REFe9EZe4NQOMAOA",
        authDomain: "llc-dashboard.firebaseapp.com",
        projectId: "llc-dashboard",
        storageBucket: "llc-dashboard.appspot.com",
        messagingSenderId: "846032691090",
        appId: "1:846032691090:web:a40076973dc5ca005bab4b",
        measurementId: "G-5EEC904W76",
      }
    : {
        apiKey: "AIzaSyAjofY2ybg2auLJ4X-Y83jpae6YBk0es3o",
        authDomain: "llc-dashboard-staging.firebaseapp.com",
        projectId: "llc-dashboard-staging",
        storageBucket: "llc-dashboard-staging.appspot.com",
        messagingSenderId: "380338064644",
        appId: "1:380338064644:web:75b4a1d30fdd4b03871451",
        measurementId: "G-NH74M80MBX",
      };

initializeApp(firebaseOptions);

export const db = getFirestore();
export const auth = getAuth();
auth.useDeviceLanguage();

export const functions = getFunctions();
export const storage = getStorage();
export const analytics = getAnalytics();

if (process.env.NODE_ENV === "development") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectStorageEmulator(storage, "localhost", 9199);
}
