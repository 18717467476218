import {
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "components";
import { RoomOutlined } from "icons";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { signInWithGoogle } from "services";
import EmailForm from "./components/EmailForm";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.light,
    height: "100vh",
  },
  actions: {
    margin: theme.spacing(2),
  },
  photo: {
    display: "flex",
    alignItems: "end",
    background: `url(${process.env.PUBLIC_URL}/monarch.jpg) center center`,
    height: "100%",
    color: theme.palette.common.white,
    backgroundSize: "cover",
    "& div": {
      margin: theme.spacing(6),
    },
    "& h1": {
      fontSize: theme.typography.h2.fontSize,
    },
    "& h6": {
      display: "flex",
      alignItems: "center",
    },
  },
  form: {
    margin: "auto",
    textAlign: "center",
  },
  logo: {
    width: 200,
    marginBottom: theme.spacing(6),
  },
  googleButton: {
    marginBottom: theme.spacing(1),
  },
  googleIcon: {
    width: 24,
  },
  divider: {
    display: "flex",
    alignItems: "center",
    margin: `${theme.spacing(2)}px 0`,
  },
  or: {
    margin: `0 ${theme.spacing(2)}px`,
  },
}));

export default function Login() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<
    | "checkEmail"
    | "loginPassword"
    | "loginMagicLink"
    | "leaveConfirmation"
    | "leaveError"
  >("checkEmail");

  const loginWithGoogle = async () => {
    try {
      setLoading(true);
      await signInWithGoogle();
    } catch {
      enqueueSnackbar(
        t("Login failed 🍂 Try again or contact with your administrator"),
        {
          variant: "error",
          preventDuplicate: true,
        }
      );
    }
    setLoading(false);
  };

  return (
    <Grid container className={classes.root} alignContent="center">
      <Hidden xsDown>
        <Grid item sm={6} className={classes.photo}>
          <div>
            <Typography variant="h1">Monarch butterfly Reserve</Typography>
            <Typography variant="subtitle1">
              <RoomOutlined />
              México
            </Typography>
          </div>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={6} className={classes.form}>
        <Container maxWidth="xs">
          <img
            src={`${process.env.PUBLIC_URL}/logo_landlifecompany.png`}
            alt="Land Life Company"
            className={classes.logo}
          />
          {!step.includes("leave") && (
            <>
              <Button
                variant="outlined"
                onClick={loginWithGoogle}
                size="large"
                fullWidth
                className={classes.googleButton}
                startIcon={
                  <img
                    src={`${process.env.PUBLIC_URL}/google_icon.png`}
                    alt="Google icon"
                    className={classes.googleIcon}
                  />
                }
                disabled={loading}
              >
                {t("Log in with Google")}
              </Button>
              <div className={classes.divider}>
                <Divider />
                <div className={classes.or}>{t("Or")}</div>
                <Divider />
              </div>
            </>
          )}
          <EmailForm
            loading={loading}
            setLoading={setLoading}
            step={step}
            setStep={setStep}
          />
        </Container>
      </Grid>
    </Grid>
  );
}
