import { AuthContext } from "providers";
import { useContext } from "react";
import { useQuery } from "react-query";
import { FilterList, queryFirestoreDocs } from "services";
import { ApiToken } from "../../../typedefs";

const useApiTokens = () => {
  const { customer } = useContext(AuthContext);
  const path: [string] = ["apiTokens"];
  const filters: FilterList = [["customer", "==", customer?.id]];
  const { data, refetch } = useQuery([path, ...filters.flat()], () =>
    customer?.id ? queryFirestoreDocs<ApiToken>(path, filters) : []
  );

  return { apiTokens: data || [], refetch };
};

export default useApiTokens;
