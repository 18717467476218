import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "components";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import { AuthContext } from "providers";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateUser, updateUserPreferences } from "services";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
}));

export default function UserProfile() {
  const { user, userPreferences } = useContext(AuthContext);
  const initialState = {
    email: user?.email || "",
    password: "",
    displayName: user?.displayName || "",
    language: "en",
    temperatureUnit: "c",
    emailUpdates: false,
    ...userPreferences,
  };

  const [userData, setUserData] = useState(initialState);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();

  const updateUserData = async () => {
    const { displayName, password, ...userPreferences } = userData;
    try {
      await updateUser({
        displayName,
        email: userPreferences.email,
        password,
      });

      if (user?.uid) {
        await updateUserPreferences(user?.uid, userPreferences);
      }
      if (i18n.language !== userPreferences.language) {
        i18n.changeLanguage(userPreferences.language);
      }
      enqueueSnackbar(t("Succesfully updated user details"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(
        t(
          "Something went wrong – refresh the page or contact your administrator"
        ),
        {
          variant: "error",
        }
      );
      setUserData(initialState);
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">{t("Your account")}</Typography>
      <TextField
        label={t("Name")}
        placeholder={t("Your name")}
        variant="outlined"
        margin="normal"
        value={userData.displayName}
        onChange={(e) =>
          setUserData({ ...userData, displayName: e.target.value })
        }
        fullWidth
      />
      <TextField
        label={t("Email address")}
        placeholder="you@company.com"
        variant="outlined"
        margin="normal"
        type="email"
        value={userData.email}
        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
        fullWidth
      />
      <TextField
        label={t("Password")}
        placeholder="*******"
        variant="outlined"
        margin="normal"
        type="password"
        value={userData.password}
        onChange={(e) => setUserData({ ...userData, password: e.target.value })}
        fullWidth
      />
      <TextField
        label={t("Language")}
        variant="outlined"
        margin="normal"
        value={userData.language}
        onChange={(e) => {
          setUserData({ ...userData, language: e.target.value });
        }}
        fullWidth
        select
      >
        <MenuItem value="en">{t("English")}</MenuItem>
        <MenuItem value="es">{t("Spanish")}</MenuItem>
        <MenuItem value="nl">{t("Dutch")}</MenuItem>
      </TextField>
      <TextField
        label={t("Temperature unit")}
        variant="outlined"
        margin="normal"
        value={userData.temperatureUnit}
        onChange={(e) =>
          setUserData({ ...userData, temperatureUnit: e.target.value })
        }
        fullWidth
        select
      >
        <MenuItem value="c">{t("Celcius")}</MenuItem>
        <MenuItem value="f">{t("Fahrenheit")}</MenuItem>
      </TextField>

      <FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={userData.emailUpdates}
              onChange={(e) =>
                setUserData({ ...userData, emailUpdates: e.target.checked })
              }
              color="primary"
            />
          }
          label={t("Email notifications")}
        />
        <FormHelperText>
          {t(
            "Get notified when new stories, updated monitoring data, or new projects are available on your account."
          )}
        </FormHelperText>
      </FormControl>

      <div>
        <Button
          onClick={updateUserData}
          variant="contained"
          color="primary"
          className={classes.submitButton}
        >
          {t("Update")}
        </Button>
      </div>
    </div>
  );
}
