import { Typography, useTheme } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { makeStyles, Tooltip } from "components";
import { Info, Pin } from "icons";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  legend: {
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: 999,
    borderRadius: 6,
    background: "white",
    padding: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
  pins: {
    display: "flex",
  },
  pinGroup: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function PlantingLegend({ className }: { className: string }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div className={`${classes.legend} ${className}`}>
      <Tooltip
        title={
          t(`This map is a mere visual representation of the total project. The
        area(s) assigned to you in this representation only visually reflect
        your percentage of contribution to the total project size. The trees you
        have funded may be planted elsewhere throughout the displayed project
        locations`) as string
        }
      >
        <div className={classes.pins}>
          <div className={classes.pinGroup}>
            <Pin
              fontSize="inherit"
              showCircle={false}
              style={{
                fill: alpha(theme.palette.primary.main, 0.8),
                strokeWidth: 2,
                marginRight: theme.spacing(0.5),
              }}
            />{" "}
            <Typography variant="body2">{t("Assigned area")}</Typography>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className={classes.pinGroup}>
            <Pin
              fontSize="inherit"
              showCircle={false}
              style={{
                strokeWidth: 2,
                marginRight: theme.spacing(0.5),
              }}
            />{" "}
            <Typography variant="body2">{t("Remaining area")}</Typography>
          </div>
          <Info
            fontSize="inherit"
            color="primary"
            style={{ marginLeft: 4, marginTop: 4 }}
          />
        </div>
      </Tooltip>
    </div>
  );
}
