import { makeStyles, MenuItem, TextField, Typography } from "components";
import { useCustomerStories, usePlantings } from "hooks";
import { CO2, Story, Tree } from "icons";
import { groupBy, uniq } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router";
import { routes } from "routes";
import { formatNumber, getTotalNumbers } from "utils";
import { Planting } from "../../../../typedefs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  plantingsTitle: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  plantingGroup: {
    marginBottom: theme.spacing(6),
  },
  card: {
    display: "flex",
    alignItems: "stretch",
    borderRadius: 6,
    overflow: "hidden",
    border: `1px solid rgba(32,39,39,0.1)`,
    boxShadow: `0px 1px 3px rgba(0,0,0,0.1)`,
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  image: {
    width: 200,
    backgroundSize: "cover",
    backgroundPosition: "center",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 150,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
    maxWidth: "60%",
  },
  plantingName: {
    lineHeight: 1.25,
  },
  plantingSeason: {
    marginBottom: theme.spacing(1),
  },
  icons: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(4),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

const ALL_COUNTRIES_FILTER = "ALL_COUNTRIES";

export default function PlantingDetailList() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { plantings } = usePlantings(true, false);
  const groupedPlantings = groupBy(plantings, (p) => p.plantingSeason);
  const history = useHistory();
  const stories = useCustomerStories();
  const [countryFilter, setCountryFilter] = useState(ALL_COUNTRIES_FILTER);
  const countryFilterFunc = (planting: Planting) =>
    [ALL_COUNTRIES_FILTER, planting.country].includes(countryFilter);

  const countries = uniq(
    Object.keys(groupedPlantings)
      .flatMap((key) => groupedPlantings[key])
      .map((planting) => planting.country)
  );

  return (
    <div className={classes.root}>
      <div className={classes.plantingsTitle}>
        <Typography variant="h1">{t("Your plantings")}</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          value={countryFilter}
          onChange={(e) => {
            setCountryFilter(e.target.value);
          }}
          select
          size="small"
        >
          <MenuItem value={ALL_COUNTRIES_FILTER}>
            {t("Show all countries")}
          </MenuItem>
          {countries.map((country) => (
            <MenuItem key={country} value={country}>
              {country}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {Object.keys(groupedPlantings)
        .filter(
          (plantingSeason) =>
            groupedPlantings[plantingSeason].filter(countryFilterFunc).length >
            0
        )
        .sort()
        .reverse()
        .map((plantingSeason) => (
          <div key={plantingSeason} className={classes.plantingGroup}>
            <Typography variant="h4">{plantingSeason}</Typography>
            {groupedPlantings[plantingSeason]
              .filter(countryFilterFunc)
              .map((planting) => {
                const allocationTotals = getTotalNumbers(planting.allocations);
                return (
                  <div
                    key={planting.id}
                    className={classes.card}
                    onClick={() => {
                      const path = generatePath(routes.plantingDetail, {
                        plantingId: planting.id,
                      });
                      history.push(path);
                    }}
                  >
                    <div
                      style={{ backgroundImage: `url(${planting?.image})` }}
                      className={classes.image}
                    />
                    <div className={classes.content}>
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        className={classes.plantingName}
                      >
                        {planting.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.plantingSeason}
                      >
                        {planting.country}
                      </Typography>
                      <div className={classes.icons}>
                        {Boolean(planting.treeCount) && (
                          <Typography variant="caption" color="textSecondary">
                            <div className={classes.iconWrapper}>
                              <Tree className={classes.icon} />{" "}
                              <span>
                                {formatNumber(
                                  allocationTotals.treeCount ||
                                    planting.treeCount
                                )}
                              </span>
                            </div>
                          </Typography>
                        )}
                        {Boolean(planting.carbonReduction) && (
                          <Typography variant="caption" color="textSecondary">
                            <div className={classes.iconWrapper}>
                              <CO2 className={classes.icon} />{" "}
                              <span>
                                {formatNumber(
                                  allocationTotals.carbonReduction ||
                                    planting.carbonReduction
                                )}
                              </span>
                            </div>
                          </Typography>
                        )}
                        <Typography variant="caption" color="textSecondary">
                          <div className={classes.iconWrapper}>
                            <Story className={classes.icon} />{" "}
                            <span>
                              {
                                stories.filter(
                                  (story) => story.planting === planting.id
                                ).length
                              }
                            </span>
                          </div>
                        </Typography>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ))}
    </div>
  );
}
