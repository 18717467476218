import { Typography } from "@material-ui/core";
import {
  IconButton,
  makeStyles,
  SettingsMenu,
  Toolbar,
  Tooltip,
} from "components";
import { Info, Pin, Settings, Story } from "icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { routes } from "routes";

const useStyles = makeStyles((theme) => ({
  root: {
    "& button": {
      color: theme.palette.common.gray,
    },
  },
  toolbar: {
    background: theme.palette.primary.light,
    height: "100vh",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  icon: {
    width: 40,
    cursor: "pointer",
    marginBottom: theme.spacing(2),
  },
  settings: {
    marginTop: "auto",
  },
}));

export default function DesktopMenu() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <img
          src={process.env.PUBLIC_URL + "/icon_landlifecompany.png"}
          alt="Land Life Company logo"
          className={classes.icon}
          onClick={() => history.push(routes.home)}
        />
        <Link to={routes.plantings}>
          <IconButton color="inherit" title={t("Your projects")}>
            <Pin fontSize="large" />
          </IconButton>
        </Link>
        <Link to={routes.stories}>
          <IconButton color="inherit" title={t("Stories")}>
            <Story fontSize="large" style={{ strokeWidth: 1.5 }} />
          </IconButton>
        </Link>
        <>
          <IconButton
            color="inherit"
            title="Settings"
            className={classes.settings}
            onClick={handleMenu}
          >
            <Settings fontSize="large" />
          </IconButton>
          <SettingsMenu onClose={handleClose} anchorEl={anchorEl} />
          <Tooltip
            title={`${t(
              "Please note that Land Life Sia is still undergoing final testing before its official release. If you encounter any problems, please let us know so we can rectify these accordingly. Your feedback is greatly appreciated!"
            )}`}
          >
            <div>
              <Typography variant={"caption"}>{t("Beta")} </Typography>
              <Info
                fontSize="inherit"
                color="primary"
                style={{ marginBottom: -3 }}
              />
            </div>
          </Tooltip>
        </>
      </Toolbar>
    </div>
  );
}
