import { AuthContext } from "providers";
import { useContext } from "react";
import { useQuery } from "react-query";
import { FilterList, queryFirestoreDocs } from "services";
import { Allocation, Planting } from "../../../typedefs";

const useCustomerAllocations = () => {
  const { customer } = useContext(AuthContext);
  const path: [string] = ["allocations"];
  const filters: FilterList = [["customer", "==", customer?.id]];
  const { data } = useQuery([...path, ...filters.flat()], () =>
    customer?.id ? queryFirestoreDocs<Allocation>(path, filters) : []
  );

  // change carbon reduction on allocation to per hectare to be in the same unit as the one on planting
  return (
    data?.map((a) => ({ ...a, carbonReduction: a.carbonReduction / a.area })) ||
    []
  );
};

export const usePlantings = (showAssigned = true, showRemaining = true) => {
  const { customer } = useContext(AuthContext);
  const customerAllocations = useCustomerAllocations();
  const path: [string] = ["plantings"];
  const { data, isLoading } = useQuery([path, customer?.id], () => {
    if (!customer) {
      return [];
    }

    const filters: FilterList = customer.showOnlyOwnPlantings
      ? [["customers", "array-contains", customer.id]]
      : [];
    return queryFirestoreDocs<Planting>(path, filters);
  });

  const plantings = (data || [])
    // plantings without coordinates shouldn't exist, but there are some cases, therefore this filter
    .filter((planting) => planting.coordinates)
    .map((planting) => {
      const isUserPlanting = customer
        ? planting.customers.includes(customer?.id)
        : false;

      const name = planting.name.split(" - ").reverse()[0];
      const image =
        planting.image ||
        "https://storage.googleapis.com/llc-dashboard.appspot.com/ImagesPlantings/749834-SC-0227.jpg";
      const allocations = customerAllocations.filter(
        (a) => a.customer === customer?.id && a.planting === planting.id
      );

      return {
        ...planting,
        name,
        isUserPlanting,
        image,
        allocations,
      };
    })
    .filter((planting) => (showAssigned ? true : !planting.isUserPlanting))
    .filter((planting) => (showRemaining ? true : planting.isUserPlanting));

  return { plantings, isLoading };
};
