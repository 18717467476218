import {
  Button,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "components";
import { usePlantings } from "hooks";
import { Gallery, Pin, PushPin, Video } from "icons";
import { orderBy } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getVideoThumb } from "utils";
import { Story } from "../../../../typedefs";
import { GallerySection } from "./components";

interface StoriesListProps {
  plantingId?: string;
  titleVariant?: "h1" | "h4";
  showFilter?: boolean;
  orderByPinned?: boolean;
  showMoreButton?: boolean;
  stories: Story[];
}

const ALL_PLANTINGS_FILTER = "ALL_PLANTINGS";
const PAGE_SIZE = 6;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  storiesTitle: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  storyWrapper: { display: "flex" },
  location: {
    display: "flex",
    color: theme.palette.text.secondary,
    alignItems: "center",
    marginBottom: theme.spacing(2),
    marginTop: 5,
  },
  mobileDate: {
    marginRight: theme.spacing(3),
    fontSize: 14,
  },
  buttonWrapper: {
    marginTop: "3em",
    textAlign: "center",
  },
  storyCard: {
    borderRadius: 6,
    overflow: "hidden",
    border: `1px solid rgba(32,39,39,0.1)`,
    cursor: "pointer",
    boxShadow: `0px 1px 3px rgba(0,0,0,0.1)`,
    height: "100%",
  },
  storyCardImage: {
    color: "white",
    height: "200px",
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  pushPin: {
    alignSelf: "flex-start",
    position: "absolute",
  },
  storyCardContent: {
    padding: theme.spacing(2),
  },
}));

const FormattedDate = ({
  date,
  className,
}: {
  date: Date;
  className?: string;
}) => (
  <Typography variant="caption" className={className}>
    {new Intl.DateTimeFormat(navigator.language, {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date)}
  </Typography>
);

const StoriesFilter = ({
  plantingFilter,
  setPlantingFilter,
  options,
}: {
  plantingFilter: string;
  setPlantingFilter: (filter: string) => void;
  options: { id: string; name: string }[];
}) => {
  const { t } = useTranslation();
  return (
    <TextField
      variant="outlined"
      margin="normal"
      value={plantingFilter}
      onChange={(e) => {
        setPlantingFilter(e.target.value);
      }}
      select
      size="small"
    >
      <MenuItem value={ALL_PLANTINGS_FILTER}>
        {t("See all your projects")}
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

const StoryCard = ({
  story,
  plantingName,
  pinned,
}: {
  story: Story;
  plantingName: string;
  pinned: boolean;
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const isGallery = story.media.length > 1;
  const firstMedia = story.media[0] || {};
  const isVideo = Boolean(firstMedia.videoUrl) && !isGallery;
  const storyDetailUrl = `/planting/${story.planting}/stories/${story.id}`;
  const hasText = Boolean(story.text);
  const history = useHistory();

  return (
    <>
      <div
        className={classes.storyCard}
        onClick={() => (hasText ? history.push(storyDetailUrl) : setOpen(true))}
      >
        <div
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 30%), url("${
              firstMedia.image || getVideoThumb(firstMedia.videoUrl)
            }")`,
          }}
          className={classes.storyCardImage}
        >
          <Typography variant="body2">
            <Pin
              fontSize="inherit"
              showCircle={false}
              style={{ strokeWidth: 2, marginBottom: -2 }}
            />{" "}
            {plantingName}
          </Typography>
          {pinned && <PushPin className={classes.pushPin} />}
          {isVideo ? <Video /> : isGallery ? <Gallery /> : null}
        </div>

        <div className={classes.storyCardContent}>
          <FormattedDate
            date={story.publishedDate?.toDate() || story.dateUpdated.toDate()}
            className={classes.mobileDate}
          />

          <Typography variant="h6" style={{ marginBottom: 0 }}>
            {story.title}
          </Typography>
        </div>
      </div>
      {open && (
        <GallerySection
          story={story}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default function StoriesList({
  plantingId,
  titleVariant = "h4",
  showFilter,
  orderByPinned = false,
  showMoreButton,
  stories,
}: StoriesListProps) {
  const classes = useStyles();
  const [plantingFilter, setPlantingFilter] = useState(ALL_PLANTINGS_FILTER);
  const { plantings } = usePlantings();
  const { t } = useTranslation();
  const history = useHistory();

  const [pages, setPages] = useState(1);
  // TODO: should probably be async at some point when there's too many stories to fetch
  let filteredStories = stories
    .filter((story) => (plantingId ? story.planting === plantingId : true))
    // filter out plantings without coordinates
    .filter(
      (story) =>
        plantings.find((planting) => planting.id === story.planting)
          ?.coordinates
    )
    .filter(
      (story) =>
        plantingFilter === ALL_PLANTINGS_FILTER ||
        story.planting === plantingFilter
    );

  filteredStories = orderBy(filteredStories, "publishedDate", "desc");

  if (orderByPinned) {
    filteredStories = orderBy(filteredStories, "isPinned", "desc");
  }

  const paginatedStories = filteredStories.slice(0, pages * PAGE_SIZE);

  if (filteredStories.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.storiesTitle}>
        <Typography variant={titleVariant}>{t("Latest stories")}</Typography>

        {showFilter && (
          <StoriesFilter
            setPlantingFilter={setPlantingFilter}
            plantingFilter={plantingFilter}
            options={plantings
              .filter((planting) =>
                stories.map((story) => story.planting).includes(planting.id)
              )
              .map((planting) => ({ id: planting.id, name: planting.name }))}
          />
        )}
      </div>

      <Grid container spacing={2}>
        {paginatedStories.map((story) => {
          const plantingName =
            plantings.find((planting) => planting.id === story.planting)
              ?.name || "";

          return (
            <Grid item xs={12} sm={6} key={story.id}>
              <StoryCard
                story={story}
                plantingName={plantingName}
                pinned={orderByPinned && story.isPinned}
              />
            </Grid>
          );
        })}
      </Grid>
      <div className={classes.buttonWrapper}>
        {showMoreButton && paginatedStories.length < filteredStories.length && (
          <Button
            variant="contained"
            onClick={() => setPages(pages + 1)}
            color="secondary"
          >
            {t("Show all stories...")}
          </Button>
        )}
        {!showMoreButton && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => history.push("/stories")}
          >
            {t("See all stories")}
          </Button>
        )}
      </div>
    </div>
  );
}
