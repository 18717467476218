import { colors, createTheme } from "components";

const baseTheme = createTheme({
  palette: {
    primary: {
      main: "#A3B07E",
      light: "#F7F4EF",
      dark: "#496C50",
      contrastText: "#202727",
    },
    secondary: {
      main: "#F7F4EF",
    },
    error: {
      main: colors.red.A400,
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#333333",
    },
    common: {
      lightGray: "#CCCCCC",
      gray: "#A3A3A3",
      HunterGreen: "#496c50",
      Olivine: "#a3b07e",
      ShadowBlue: "#6a8ca7",
      LightBlue: "#bad1d6",
      CafeNoir: "#573b24",
      Almond: "#dfd0c2",
      Isabelline: "#f7f4ef",
      Jet: "#333333",
      QuickSilver: "#a3a3a3",
      BrightGreen: "#BFD18B",
    },
  },
});

// A custom theme for this app
const theme = createTheme({
  ...baseTheme,
  typography: {
    fontFamily: "Avenir",
    caption: {
      color: baseTheme.palette.common.gray,
    },
    h1: {
      fontFamily: "Spectral",
      fontSize: "2rem",
    },
    h2: {
      fontFamily: "Spectral",
      fontSize: "1.5rem",
    },
    h3: {
      fontWeight: 700,
      fontSize: "1.5rem",
      marginBottom: baseTheme.spacing(2),
    },
    h4: {
      textTransform: "uppercase",
      fontWeight: 900,
      marginBottom: baseTheme.spacing(2),
      letterSpacing: 3,
      fontSize: "0.875rem",
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    subtitle1: {
      color: baseTheme.palette.common.gray,
    },
    subtitle2: { fontSize: baseTheme.typography.subtitle1.fontSize },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: "#fff",
        borderRadius: 6,
        "& $notchedOutline": {
          border: "1px solid rgba(32, 39, 39, 0.1)",
          transition: "all 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        },
        "&:hover $notchedOutline": {
          transition: "all 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.05)",
          borderWidth: "1px",
          borderColor: "rgba(32, 39, 39, 0.2)",
        },
        "&$focused $notchedOutline": {
          transition: "all 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.05)",
          borderWidth: "1px",
          borderColor: "rgba(32, 39, 39, 0.2)",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "inherit",
      },
      contained: {
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.05)",
      },
      outlined: {
        border: "1px solid rgba(32, 39, 39, 0.1)",
      },
      outlinedSizeLarge: {
        padding: "12px 21px",
      },
      containedSizeLarge: {
        padding: "12px 21px",
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: baseTheme.spacing(1),
      },
    },
  },
});

export default theme;
