import { SvgIconProps } from "@material-ui/core";
import { SvgIcon } from "components";

export default function Gallery(props: SvgIconProps) {
  const { style, ...restProps } = props;
  return (
    <SvgIcon
      viewBox="0 0 26 26"
      style={{
        stroke: "none",
        ...style,
        fill: "currentColor",
      }}
      {...restProps}
    >
      <path d="M23.1729 5.29837H20.6984V2.82387C20.6984 1.67987 19.7688 0.750231 18.6248 0.750231L2.82364 0.75C1.67964 0.75 0.75 1.67964 0.75 2.82364V18.6283C0.75 19.7723 1.67964 20.702 2.82364 20.702H18.6283C19.7723 20.702 20.702 19.7723 20.702 18.6283V6.68061H23.1765C23.5567 6.68061 23.8678 6.99166 23.8678 7.3719V23.1766C23.8678 23.5568 23.5567 23.8679 23.1765 23.8679L7.37238 23.8677C6.99213 23.8677 6.68108 23.5566 6.68108 23.1764V22.0842H5.29874V23.1764C5.29874 24.3204 6.22838 25.25 7.37238 25.25H23.1771C24.3211 25.25 25.2507 24.3204 25.2507 23.1764V7.37226C25.2472 6.22827 24.3176 5.29862 23.1736 5.29862L23.1729 5.29837ZM19.3158 18.6248C19.3158 19.005 19.0048 19.3161 18.6245 19.3161H2.8234C2.44316 19.3161 2.13211 19.005 2.13211 18.6248L2.13234 11.0975L4.58623 8.64357C4.84891 8.38089 5.30158 8.38089 5.56428 8.64357L11.8231 14.9024L12.8012 13.9244L11.3082 12.4314L12.4936 11.246C12.7528 10.9868 13.209 10.9868 13.4717 11.246L19.3228 17.0971V18.6245L19.3158 18.6248ZM19.3158 15.1412L14.4428 10.2682C14.0524 9.87775 13.5305 9.65995 12.9774 9.65995C12.4245 9.65995 11.9026 9.87426 11.512 10.2682L10.3265 11.4536L6.53889 7.6657C6.14847 7.27528 5.62661 7.05747 5.07348 7.05747C4.52059 7.05747 3.99868 7.27178 3.60807 7.6657L2.13223 9.14154L2.13246 2.82346C2.13246 2.44321 2.44351 2.13216 2.82375 2.13216H18.6284C19.0087 2.13216 19.3197 2.44321 19.3197 2.82346L19.3195 15.1409L19.3158 15.1412Z" />
    </SvgIcon>
  );
}
