import { SvgIconProps } from "@material-ui/core";
import { SvgIcon } from "components";

export default function Pin({
  style,
  showCircle = true,
  ...restProps
}: SvgIconProps & { showCircle?: boolean }) {
  return (
    <SvgIcon
      viewBox="0 0 32 32"
      style={{
        stroke: "currentColor",
        strokeWidth: 1.5,
        fill: "none",
        ...style,
      }}
      {...restProps}
    >
      <path d="M16 30L15.5087 30.5667L16 30.9926L16.4913 30.5667L16 30ZM16 30C16.4913 30.5667 16.4914 30.5666 16.4916 30.5664L16.4922 30.5659L16.494 30.5643L16.5007 30.5585L16.5258 30.5366C16.5476 30.5175 16.5795 30.4894 16.6208 30.4528C16.7035 30.3796 16.8239 30.2721 16.9766 30.1335C17.282 29.8564 17.717 29.4547 18.2385 28.9535C19.2804 27.952 20.672 26.5492 22.0658 24.9472C23.4572 23.3479 24.8651 21.5338 25.9291 19.71C26.9833 17.9028 27.75 16.0022 27.75 14.25C27.75 11.0728 26.5161 8.02238 24.3144 5.77071C22.1122 3.51847 19.1218 2.25 16 2.25C12.8782 2.25 9.88776 3.51847 7.68557 5.77071C5.48394 8.02238 4.25 11.0728 4.25 14.25C4.25 16.0022 5.01669 17.9028 6.07092 19.71C7.13487 21.5338 8.54281 23.3479 9.93417 24.9472C11.328 26.5492 12.7196 27.952 13.7615 28.9535C14.283 29.4547 14.718 29.8564 15.0234 30.1335C15.1761 30.2721 15.2965 30.3796 15.3792 30.4528C15.4205 30.4894 15.4524 30.5175 15.4742 30.5366L15.4993 30.5585L15.506 30.5643L15.5078 30.5659L15.5084 30.5664C15.5086 30.5666 15.5087 30.5667 16 30Z" />
      {showCircle && <circle strokeWidth={1.5} cx="16" cy="13" r="3.75" />}
    </SvgIcon>
  );
}
