import { makeStyles, Typography } from "components";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseInit";
import { usePlanting } from "hooks";
import { AuthContext } from "providers";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Planting } from "../../../../typedefs";

interface WeatherProps {
  className: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(3)}px`,
    display: "flex",
    alignItems: "center",
    height: 50,
  },
  text: {
    textAlign: "right",
  },
}));

const useWeatherData = (planting?: Planting) => {
  const { userPreferences } = useContext(AuthContext);
  const unitsMap = {
    c: "metric",
    f: "imperial",
  };
  const getPlantingWeather = httpsCallable(functions, "getPlantingWeather");
  const { data } = useQuery(
    [planting?.id, userPreferences?.language],
    () =>
      planting &&
      getPlantingWeather({
        lat: planting.coordinates.lat,
        lon: planting.coordinates.lng,
        units: unitsMap[(userPreferences?.temperatureUnit as "c" | "f") || "c"],
        lang: userPreferences?.language,
      })
  );

  return data?.data as unknown as {
    current: { temp: number; weather: { description: string; icon: string }[] };
  };
};

export default function Weather({ className }: WeatherProps) {
  const classes = useStyles();
  const { plantingId } = useParams<{ plantingId: string }>();
  const planting = usePlanting(plantingId);
  const { userPreferences } = useContext(AuthContext);
  const weatherData = useWeatherData(planting);
  if (
    !weatherData ||
    !weatherData.current ||
    weatherData.current.weather.length === 0
  ) {
    return null;
  }

  const current = weatherData.current;
  const weather = current.weather[0];

  return (
    <div className={`${className} ${classes.root}`}>
      <Typography variant="body1" className={classes.text}>
        {Math.round(current.temp)} °
        {userPreferences?.temperatureUnit?.toUpperCase()}
        <br />
        {weather.description}
      </Typography>
      <img
        src={`http://openweathermap.org/img/wn/${weather.icon}.png`}
        alt={weather.description}
      />
    </div>
  );
}
