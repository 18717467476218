import { makeStyles, Typography } from "components";
import { AuthContext } from "providers";
import React, { useContext } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  name: {},
  logo: {
    float: "right",
    maxWidth: 100,
    maxHeight: 50,
  },
}));

export default function CustomerDetails() {
  const { customer, user } = useContext(AuthContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={customer?.logo} alt={customer?.name} className={classes.logo} />
      <Typography variant="h1" className={classes.name}>
        {customer?.name}
      </Typography>
      <Typography variant="subtitle1">{user?.email}</Typography>
    </div>
  );
}
