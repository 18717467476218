import { useQuery } from "react-query";
import { getFirestoreDoc } from "services";
import { PlantingPolygon } from "../../../typedefs";

export const usePlantingPolygons = (plantingId: string) => {
  const path: [string, string] = ["plantingPolygons", plantingId];
  const { data } = useQuery(path, () => getFirestoreDoc<PlantingPolygon>(path));

  return data;
};
