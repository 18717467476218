import { makeStyles, Tooltip, Typography } from "components";
import React from "react";
import { formatNumber } from "utils";

interface KeyNumberProps {
  number?: number;
  suffix?: string;
  description: string;
  icon?: React.ReactElement;
  className?: string;
  color?: string;
  tooltip?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "100%",
    borderRadius: 6,
    backgroundColor: theme.palette.primary.light,
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  number: {
    fontWeight: 700,
    fontSize: theme.typography.h1.fontSize,
    marginBottom: -theme.spacing(1),
    "& span": {
      fontSize: theme.typography.h3.fontSize,
    },
    [theme.breakpoints.down("xs")]: {
      flex: 1,
    },
  },
  description: {},
  icon: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  tooltip: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: `0px 0px 2px #ccc`,
    fontSize: theme.typography.body2.fontSize,
  },
  arrow: {
    "&:before": {
      boxShadow: `0px 0px 2px #ccc`,
    },
    color: theme.palette.common.white,
  },
}));

export default function KeyNumber({
  number,
  suffix = "",
  description,
  icon,
  className,
  color,
  tooltip = "",
}: KeyNumberProps) {
  const classes = useStyles();

  const formattedNumber = number || number === 0 ? formatNumber(number) : "-";

  const numberMatch = formattedNumber.match(/\d*\.?\d+/);
  const numberPart = numberMatch ? numberMatch[0] : formattedNumber;

  const suffixMatch = formattedNumber.match(/[^\d*|.|,]/);
  const suffixPart =
    suffixMatch && numberPart !== formattedNumber ? suffixMatch[0] : "";

  return (
    <Tooltip
      title={tooltip}
      placement="top"
      arrow
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
    >
      <div
        className={`${classes.root} ${className}`}
        style={{ backgroundColor: color }}
      >
        <div className={classes.icon}>{icon}</div>
        <Typography variant="body1" className={classes.number}>
          {numberPart}
          <span>
            {suffixPart}
            {suffix}
          </span>
        </Typography>
        <Typography variant="subtitle2">{description}</Typography>
      </div>
    </Tooltip>
  );
}
