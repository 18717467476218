import { AuthContext } from "providers";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Filter, queryFirestoreDocs } from "services";
import { getFirestoreDocs } from "services/firestore";
import { Story } from "../../../typedefs";
import { usePlantings } from "./usePlantings";

const path: [string] = ["stories"];
const publishedFilter: Filter = ["status", "==", "published"];

export const useStory = (id: string) => {
  const { data = [] } = useQuery([...path, id], () =>
    getFirestoreDocs<Story>([[...path, id]])
  );
  return data.length > 0 ? data[0] : null;
};

export const usePublicStories = (filters: Filter[] = []) => {
  const { userPreferences } = useContext(AuthContext);

  const publicFilter: Filter = ["isPublic", "==", true];
  const languageFilter: Filter = [
    "language",
    "in",
    [userPreferences?.language, ""],
  ];

  const { data = [] } = useQuery(
    [...path, ...publishedFilter, ...languageFilter, ...publicFilter],
    () =>
      queryFirestoreDocs<Story>(
        path,
        [publishedFilter, languageFilter, publicFilter, ...filters],
        [["publishedDate", "desc"]]
      )
  );
  return data;
};

export const useCustomerStories = (filters: Filter[] = []) => {
  const { customer, userPreferences } = useContext(AuthContext);
  const { plantings } = usePlantings();
  const customerFilter: Filter = ["customer", "in", [customer?.id, ""]];

  const { data = [] } = useQuery(
    [...path, ...publishedFilter, ...customerFilter],
    () => {
      if (!customer?.id) {
        return [];
      }
      return queryFirestoreDocs<Story>(
        path,
        [publishedFilter, customerFilter, ...filters],
        [["publishedDate", "desc"]]
      );
    }
  );

  return data.filter(
    (story) =>
      plantings
        .filter((p) => p.isUserPlanting)
        .map((p) => p.id)
        .includes(story.planting) &&
      [userPreferences?.language, ""].includes(story.language)
  );
};
