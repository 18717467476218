import { useMediaQuery, useTheme } from "@material-ui/core";
import { Dialog, makeStyles, Typography } from "components";
import { ArrowForwardIos } from "icons";
import { useState } from "react";
import ReactPlayer from "react-player";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Story } from "../../../../../typedefs";

interface GallerySectionProps {
  story: Story;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: "100%",
    maxHeight: "80vh",
  },
  arrowForward: {
    position: "absolute",
    right: -42,
    cursor: "pointer",
    marginRight: theme.spacing(1),
  },
  arrowBack: {
    transform: "rotate(180deg)",
    position: "absolute",
    left: -42,
    cursor: "pointer",
    marginLeft: theme.spacing(1),
  },
  fade: {
    display: "flex",
    flexDirection: "column",
    "&-enter": {
      opacity: 0,
    },
    "&-enter-active": {
      opacity: 1,
      transition: "all 300ms",
    },
    "&-appear": {
      opacity: 0,
    },
    "&-appear-active": {
      opacity: 1,
      transition: "all 300ms",
    },
    "&-exit": {
      opacity: 1,
    },
    "&-exit-active": {
      opacity: 0,
      transition: "all 300ms",
    },
  },
  backdrop: { background: "rgba(255,255,255,0.5)" },
  dialogPaper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `${theme.spacing(2)}px`,
    overflow: "visible",
    maxWidth: "80vw",
  },
}));

export function GallerySection({ story, open, onClose }: GallerySectionProps) {
  const classes = useStyles();
  const { media = [] } = story;
  const [index, setIndex] = useState(0);
  const image = media[index];
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{ className: classes.backdrop }}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      {media.length > 1 && (
        <ArrowForwardIos
          fontSize="large"
          onClick={() => setIndex(index === 0 ? media.length - 1 : index - 1)}
          className={classes.arrowBack}
        />
      )}
      <SwitchTransition mode="out-in">
        <CSSTransition
          timeout={300}
          classNames={classes.fade}
          appear
          key={index}
          className={classes.fade}
        >
          <>
            {image.videoUrl ? (
              <ReactPlayer
                url={image.videoUrl}
                width={isBigScreen ? undefined : "100%"}
                height={isBigScreen ? undefined : "100%"}
                playing={true}
                muted={true}
                controls={true}
              />
            ) : (
              <img
                src={image.image}
                alt={image.caption}
                className={classes.image}
              />
            )}
            <Typography variant="subtitle1">{image.caption}</Typography>
          </>
        </CSSTransition>
      </SwitchTransition>
      {media.length > 1 && (
        <ArrowForwardIos
          fontSize="large"
          onClick={() => setIndex(index + 1 < media.length ? index + 1 : 0)}
          className={classes.arrowForward}
        />
      )}
    </Dialog>
  );
}
