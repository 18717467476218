import { alpha, useTheme } from "@material-ui/core";
import { makeStyles, Typography } from "components";
import { Check } from "icons";
import { StateContext } from "providers";
import { CSSProperties, useContext } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  legend: {
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: 999,
    borderRadius: 6,
    background: "white",
    padding: theme.spacing(1),
    display: "flex",
    fontSize: theme.typography.caption.fontSize,
  },
  pinGroup: {
    display: "flex",
    alignItems: "center",
  },
  switch: {
    position: "relative",
    borderRadius: 16,
    width: 28,
    height: 18,
    padding: 2,
    cursor: "pointer",
    boxShadow: `1px 1px 3px ${theme.palette.common.lightGray}`,
    border: `1px solid ${theme.palette.common.lightGray}`,
    background: "white",
    transition: "0.5s all",
    "& .switcher": {
      // position: "absolute",
      borderRadius: "50%",
      width: 12,
      height: 12,
      background: theme.palette.common.lightGray,
      transition: "0.5s all",
      display: "flex",
    },
    "&.on": {
      transition: "0.5s all",
      border: `1px solid ${theme.palette.common.Olivine}`,
      "& .switcher": {
        transform: `translateX(10px)`,
        background: "white",
        transition: "0.5s all",
      },
    },
  },
}));

const Switch = ({
  color,
  on,
  toggle,
  className = "",
  style = {},
}: {
  color: string;
  toggle: () => void;
  on: boolean;
  className?: string;
  style?: CSSProperties;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div
      className={`${classes.switch} ${on ? "on" : "off"} ${className}`}
      style={{
        background: on ? alpha(color, 0.5) : "white",
        border: `1px solid ${on ? color : theme.palette.common.lightGray}`,
        ...style,
      }}
      onClick={toggle}
    >
      <div
        className="switcher"
        style={{
          color: on
            ? theme.palette.common.black
            : theme.palette.common.lightGray,
        }}
      >
        <Check style={{ padding: 1 }} fontSize="inherit" />
      </div>
    </div>
  );
};

export default function Legend({ className }: { className: string }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { setStateContextValue, showAssigned, showRemaining } =
    useContext(StateContext);

  return (
    <div className={`${classes.legend} ${className}`}>
      <div className={classes.pinGroup}>
        <Switch
          color={theme.palette.common.Olivine}
          style={{ marginRight: theme.spacing(1) }}
          toggle={() => setStateContextValue("showAssigned", !showAssigned)}
          on={showAssigned}
        />{" "}
        <Typography variant="body2">{t("Assigned area")}</Typography>
      </div>
      &nbsp;&nbsp;&nbsp;
      <div className={classes.pinGroup}>
        <Switch
          color={theme.palette.common.lightGray}
          style={{ marginRight: theme.spacing(1) }}
          toggle={() => setStateContextValue("showRemaining", !showRemaining)}
          on={showRemaining}
        />{" "}
        <Typography variant="body2">{t("Remaining area")}</Typography>
      </div>
    </div>
  );
}
