import { Button, TextField, Typography } from "components";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { checkIfUserExists, sendMagicLink, signInWithPassword } from "services";

// const useStyles = makeStyles((theme) => ({}));

interface EmailFormProps {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  step:
    | "checkEmail"
    | "loginPassword"
    | "loginMagicLink"
    | "leaveConfirmation"
    | "leaveError";
  setStep: (step: EmailFormProps["step"]) => void;
}

export default function EmailForm({
  loading,
  setLoading,
  step,
  setStep,
}: EmailFormProps) {
  // const classes = useStyles();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const buttonText = {
    checkEmail: t("Continue"),
    loginPassword: t("Login"),
    loginMagicLink: t("Log in with an email code"),
    leaveConfirmation: t("Back"),
    leaveError: t("Back"),
  };
  const action = {
    checkEmail: async () => {
      setLoading(true);
      const userExists = await checkIfUserExists(email);
      if (userExists) {
        setStep("loginMagicLink");
      } else {
        await action["loginMagicLink"](true);
      }
      setLoading(false);
    },
    loginMagicLink: async (newUser?: boolean) => {
      setLoading(true);
      try {
        await sendMagicLink(email, newUser);
        setStep("leaveConfirmation");
      } catch (e) {
        setStep("leaveError");
      }
      setLoading(false);
    },
    loginPassword: async () => {
      try {
        setLoading(true);
        await signInWithPassword(email, password);
      } catch {
        enqueueSnackbar(
          t("Login failed 🍂 Try again or contact with your administrator"),
          {
            variant: "error",
            preventDuplicate: true,
          }
        );
      }
      setLoading(false);
    },
    leaveConfirmation: async () => {
      setStep("checkEmail");
    },
    leaveError: async () => {
      setStep("checkEmail");
    },
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      await action[step]();
    } catch {
      setStep("leaveError");
    }
    setLoading(false);
  };
  return (
    <>
      {!step.includes("leave") && (
        <TextField
          autoFocus
          placeholder={t("Email")}
          type="email"
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          disabled={loading || step !== "checkEmail"}
        />
      )}
      {step === "loginPassword" && (
        <TextField
          placeholder={t("Password")}
          type="password"
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          disabled={loading}
        />
      )}

      {step === "leaveError" && (
        <Typography variant="body1">
          {t(
            `Your email address has not been registered yet. Please review the information or contact your administrator.`
          )}
          .
        </Typography>
      )}

      {step === "leaveConfirmation" && (
        <Typography variant="body1" gutterBottom>
          {t(`Great, we just sent a link to your email!`)}
        </Typography>
      )}

      <Button
        style={{ marginTop: 8 }}
        onClick={handleClick}
        color="primary"
        size="large"
        variant={step.includes("leave") ? "outlined" : "contained"}
        disabled={loading}
        fullWidth={!step.includes("leave")}
      >
        {buttonText[step]}
      </Button>

      {step === "loginMagicLink" && (
        <Button
          onClick={() => setStep("loginPassword")}
          variant="text"
          disabled={loading}
        >
          {t("Log in with password")}
        </Button>
      )}

      {step === "loginPassword" && (
        <Button
          onClick={() => setStep("loginMagicLink")}
          variant="text"
          disabled={loading}
        >
          {t("Get a login code")}
        </Button>
      )}
    </>
  );
}
