import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "components";
import { Delete, Share } from "icons";
import { useSnackbar } from "notistack";
import { AuthContext } from "providers";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { FilterList, queryFirestoreDocs } from "services";
import { createShareCode, deleteShareCode } from "services/firestore";
import { ShareCode } from "../../../../typedefs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

export const useShareCodes = () => {
  const { customer } = useContext(AuthContext);
  const path: [string] = ["shareCodes"];
  const filters: FilterList = [["customer", "==", customer?.id]];
  const { data, refetch } = useQuery([path, ...filters.flat()], () =>
    customer?.id ? queryFirestoreDocs<ShareCode>(path, filters) : []
  );

  return { shareCodes: data || [], refetch };
};

export default function ShareCodes() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const { shareCodes, refetch } = useShareCodes();
  const { customer } = useContext(AuthContext);
  const initialShareCode = {
    label: "",
    validUntil: "",
    customer: customer?.id || "",
  };
  const [shareCode, setShareCode] = useState(initialShareCode);

  return (
    <div className={classes.root}>
      <Typography variant="h4">{t("Sharing your dashboard")}</Typography>
      <Typography variant="body1">
        {t(
          "Share links give access to your personal dashboard! Visitors will see all your plantings and stories, but are not able to see or edit your account information."
        )}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Label")}</TableCell>
              <TableCell align="right">{t("Valid until")}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shareCodes.map((shareCode) => {
              const dateOptions: Intl.DateTimeFormatOptions = {
                year: "numeric",
                month: "long",
                day: "numeric",
              };
              const formattedDate = shareCode.validUntil
                ? shareCode.validUntil
                    .toDate()
                    .toLocaleDateString(navigator.language, dateOptions)
                : t("No expiration date");
              return (
                <TableRow key={shareCode.id}>
                  <TableCell component="th" scope="row">
                    {shareCode.label || "-"}
                  </TableCell>
                  <TableCell align="right">{formattedDate}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        deleteShareCode(shareCode.id);
                        refetch();
                        enqueueSnackbar(t("Share link deleted"), {
                          variant: "success",
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                    <IconButton
                      aria-label="share"
                      size="small"
                      onClick={async () => {
                        await navigator.clipboard.writeText(
                          `${window.location.origin}?shareCode=${shareCode.id}`
                        );
                        enqueueSnackbar(t("Share link copied to clipboard!"));
                      }}
                    >
                      <Share />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TextField
        InputLabelProps={{ shrink: true }}
        placeholder={t("Label")}
        fullWidth
        onChange={(e) => setShareCode({ ...shareCode, label: e.target.value })}
        value={shareCode.label}
        variant="outlined"
        margin="normal"
      />
      <TextField
        placeholder={t("Valid until")}
        InputLabelProps={{ shrink: true }}
        fullWidth
        type="date"
        onChange={(e) =>
          setShareCode({ ...shareCode, validUntil: e.target.value })
        }
        value={shareCode.validUntil}
        variant="outlined"
        margin="normal"
      />
      <Button
        onClick={async () => {
          await createShareCode(shareCode);
          setShareCode(initialShareCode);
          refetch();
          enqueueSnackbar(t("Share code added"), {
            variant: "success",
          });
        }}
        variant="contained"
        color="primary"
      >
        {t("Add share link")}
      </Button>
    </div>
  );
}
