import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
  WhereFilterOp,
} from "firebase/firestore";
import { db } from "firebaseInit";
import {
  ApiToken,
  Customer,
  ShareCode,
  UserPreferences,
  UserProfile,
} from "../../../typedefs";

export type Filter = [string, WhereFilterOp, unknown];
export type FilterList = Array<Filter>;

type Ordering = [string, "desc" | "asc"];
export type OrderingList = Array<Ordering>;

export async function getFirestoreDoc<T>(path: [string, ...string[]]) {
  const docRef = doc(db, ...path);
  const result = await getDoc(docRef);
  if (result.exists()) {
    return { id: result.id, ...result.data() } as unknown as T;
  }

  return;
}

export async function getFirestoreDocs<T>(paths: [string, ...string[]][]) {
  const result = await Promise.all(
    paths.map((path) => getDoc(doc(db, ...path)))
  );
  return result.map(
    (data) =>
      ({
        id: data.id,
        ...data.data(),
      } as unknown as T)
  );
}

export async function queryFirestoreDocs<T>(
  path: [string, ...string[]],
  filters: FilterList = [],
  ordering: OrderingList = [],
  number?: number
) {
  const collectionRef = collection(db, ...path);

  const constraints = filters.map((filter) => where(...filter));
  constraints.push(...ordering.map((order) => orderBy(...order)));
  if (number) {
    constraints.push(limit(number));
  }

  const q =
    filters.length > 0 ? query(collectionRef, ...constraints) : collectionRef;

  const result = await getDocs(q);
  return result.docs.map(
    (doc) => ({ id: doc.id, ...doc.data() } as unknown as T)
  );
}

export async function getUserProfileForUser(userUid: string) {
  const userProfilePath: [string, string] = ["userProfiles", userUid];
  const userProfile = await getFirestoreDoc<UserProfile>(userProfilePath);
  if (!userProfile) {
    throw new Error("UserProfile not found");
  }
  return userProfile;
}

export async function getUserPreferencesForUser(userUid: string) {
  const userPreferencesPath: [string, string] = ["userPreferences", userUid];
  const userPreferences = await getFirestoreDoc<UserPreferences>(
    userPreferencesPath
  );
  if (!userPreferences) {
    throw new Error("UserPreferences not found");
  }
  return userPreferences;
}

export async function getCustomer(customerId?: string) {
  if (!customerId) {
    throw new Error("Customer not found");
  }
  const customerPath: [string, string] = ["customers", customerId];
  const customer = await getFirestoreDoc<Customer>(customerPath);
  if (!customer) {
    throw new Error("Customer not found");
  }
  return customer;
}

export async function getShareCode(shareCodeId: string) {
  const shareCodePath: [string, string] = ["shareCodes", shareCodeId];
  const shareCode = await getFirestoreDoc<ShareCode>(shareCodePath);
  if (!shareCode) {
    throw new Error("Invalid share link");
  }
  return shareCode;
}

export async function createShareCode(
  data: Partial<{ label: string; validUntil: string }>
) {
  const path: [string] = ["shareCodes"];
  const shareCodeCollection = collection(db, ...path);
  const res = await addDoc(shareCodeCollection, {
    ...data,
    validUntil: data.validUntil ? new Date(data.validUntil) : null,
  });
  return { ...data, id: res.id };
}

export async function deleteShareCode(shareCodeId: string) {
  const shareCodeRef = doc(db, "shareCodes", shareCodeId);
  deleteDoc(shareCodeRef);
}

export async function createApiToken(data: Partial<ApiToken>) {
  const path: [string] = ["apiTokens"];
  const apiTokenCollection = collection(db, ...path);
  const res = await addDoc(apiTokenCollection, data);
  return { ...data, id: res.id };
}

export async function deleteApiToken(apiTokenId: string) {
  const apiTokenRef = doc(db, "apiTokens", apiTokenId);
  deleteDoc(apiTokenRef);
}

export async function updateUserPreferences(
  userUid: string,
  data: Partial<UserPreferences>
) {
  const path: [string, string] = ["userPreferences", userUid];
  const docRef = doc(db, ...path);

  await setDoc(docRef, data);
  return data;
}
