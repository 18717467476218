import { plotsToGeoJSON } from "./geojson";

export const getVideoThumb = (url?: string) => {
  if (!url) {
    return "";
  }

  const isYoutube = url.includes("youtu");
  if (isYoutube) {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    const id = match && match[7].length === 11 ? match[7] : "";
    return `https://i3.ytimg.com/vi/${id}/hqdefault.jpg`;
  }

  return "";
};

export const formatNumber = (
  number: number,
  notation: "compact" | "standard" = "compact"
) => {
  return new Intl.NumberFormat("en-US", {
    notation,
  }).format(number);
};

export const getTotalNumbers = (
  obj: { area: number; carbonReduction: number; treeCount: number }[]
) => {
  return obj?.reduce(
    (prev, curr) => ({
      area: prev.area + curr.area,
      carbonReduction: prev.carbonReduction + curr.carbonReduction * curr.area,
      treeCount: prev.treeCount + curr.treeCount,
    }),
    { area: 0, carbonReduction: 0, treeCount: 0 }
  );
};

export { plotsToGeoJSON };
