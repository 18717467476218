import { useTheme } from "@material-ui/core";
import bbox from "@turf/bbox";
import booleanIntersects from "@turf/boolean-intersects";
import { usePlanting, usePlantingPolygons } from "hooks";
import { latLngBounds, LatLngBounds } from "leaflet";
import { useEffect, useState } from "react";
import { GeoJSON, useMapEvents } from "react-leaflet";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "routes";
import { plotsToGeoJSON } from "utils/geojson";

function Allocation() {
  const theme = useTheme();
  const history = useHistory();
  const { plantingId } = useParams<{ plantingId: string }>();
  const plantingPolygons = usePlantingPolygons(plantingId);
  const plotsGeojson = plotsToGeoJSON(plantingPolygons?.multipolygons);
  const planting = usePlanting(plantingId);
  const allocationsGeojson = plotsToGeoJSON(
    planting?.allocations.flatMap((allocation) => allocation.multipolygon)
  );

  const [initialZoom, setInitialZoom] = useState(0);
  const map = useMapEvents({
    zoomstart: (event) => setInitialZoom(event.target.getZoom()),
    zoomend: (event) => {
      const zoom = event.target.getZoom();
      if (zoom < initialZoom && zoom < 12) {
        history.push(routes.home);
      }
    },
  });

  const [oldBounds, setOldBounds] = useState<LatLngBounds>();
  useEffect(() => {
    if (!oldBounds) {
      setOldBounds(map.getBounds());
    }

    if (!plantingPolygons) {
      return;
    }
    const [minLng, minLat, maxLng, maxLat] = bbox(plotsGeojson);
    const newBounds = latLngBounds([minLat, minLng], [maxLat, maxLng]);

    // we don't want to animate the map if we're already on the allocation view.
    // Flying to the same position will give an ugly, shaky effect.
    if (map.getZoom() < 12) {
      map.flyToBounds(newBounds, { duration: 2 });
    } else {
      map.fitBounds(newBounds);
    }
    return () => {
      oldBounds && map.flyToBounds(oldBounds, { duration: 2 });
    };
  }, [oldBounds, plantingPolygons, plotsGeojson, map]);

  return (
    <>
      <GeoJSON
        data={plotsGeojson}
        style={{ fillColor: "white", fillOpacity: 0.8, color: "white" }}
        key={plantingPolygons?.id}
        filter={(feat) =>
          !allocationsGeojson.features.some((all) =>
            booleanIntersects(feat, all)
          )
        }
      />
      <GeoJSON
        data={allocationsGeojson}
        style={{
          fillColor: theme.palette.primary.main,
          fillOpacity: 0.8,
          color: theme.palette.primary.main,
        }}
        // passing key because https://github.com/PaulLeCam/react-leaflet/issues/332
        key={planting?.allocations.map((a) => a.id).join()}
      />
    </>
  );
}

export default Allocation;
