import { useTheme } from "@material-ui/core";
import { Button, makeStyles, Typography } from "components";
import { usePlantings } from "hooks";
import { Pin, Tree } from "icons";
import { groupBy } from "lodash";
import { useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { getTotalNumbers } from "utils";

const plantingLimit = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  plantingGroup: {
    marginBottom: theme.spacing(3),
  },
  emptyPlantingGroup: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    marginBottom: theme.spacing(2),
    border: `1px solid rgba(32, 39, 39, 0.1)`,
    textAlign: "left",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.03)",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  image: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      1
    )}px ${theme.spacing(1)}px`,
    width: 50,
    height: 50,
    [theme.breakpoints.down("xs")]: {
      float: "left",
    },
  },
  textWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  title: {
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
    },
  },
  countryWrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    "& svg": {
      marginRight: theme.spacing(0.5),
    },
  },
  morePlantingsButton: {
    display: "block",
    marginTop: -theme.spacing(2),
  },
}));

export default function PlantingList() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { plantings } = usePlantings(true, false);
  const groupedPlantings = groupBy(plantings, (p) => p.plantingSeason);
  const history = useHistory();
  const theme = useTheme();
  const plantingCount = Object.values(groupedPlantings).flat().length;

  let i = 0;

  return (
    <div className={classes.root}>
      {Object.keys(groupedPlantings)
        .sort()
        .reverse()
        .map((plantingSeason) => (
          <div
            key={plantingSeason}
            className={
              i > plantingLimit
                ? classes.emptyPlantingGroup
                : classes.plantingGroup
            }
          >
            {i >= plantingLimit ? null : (
              <Typography variant="h4">{plantingSeason}</Typography>
            )}
            {groupedPlantings[plantingSeason].map((planting) => {
              i++;
              if (i > plantingLimit) {
                return i === plantingCount ? (
                  <Link
                    to={`/plantings`}
                    className={classes.morePlantingsButton}
                    key={planting.id}
                  >
                    <Button variant="outlined">
                      {t("See all your projects")}
                    </Button>
                  </Link>
                ) : null;
              }

              const allocationTotals = getTotalNumbers(planting.allocations);
              return (
                <Button
                  onClick={() =>
                    history.push(
                      generatePath(routes.plantingDetail, {
                        plantingId: planting.id,
                      })
                    )
                  }
                  key={planting.id}
                  className={classes.listItem}
                  variant="outlined"
                  fullWidth
                >
                  <img src={planting.svgUrl} className={classes.image} alt="" />

                  <Typography variant="h2" className={classes.title}>
                    {planting.name}
                  </Typography>

                  <div className={classes.textWrapper}>
                    <span className={classes.countryWrapper}>
                      <Pin
                        showCircle={false}
                        fontSize="inherit"
                        style={{
                          strokeWidth: 3,
                          stroke: theme.palette.text.secondary,
                        }}
                      />
                      <Typography variant="subtitle1">
                        {planting.country}
                      </Typography>
                    </span>
                    <span className={classes.countryWrapper}>
                      <Tree
                        fontSize="inherit"
                        style={{
                          strokeWidth: 2,
                          stroke: theme.palette.text.secondary,
                        }}
                      />
                      <Typography variant="subtitle1">
                        {new Intl.NumberFormat(navigator.language, {
                          notation: "compact",
                        }).format(
                          allocationTotals.treeCount || planting.treeCount
                        )}{" "}
                        {t("Trees")}
                      </Typography>
                    </span>
                  </div>
                </Button>
              );
            })}
          </div>
        ))}
    </div>
  );
}
