import { feature, featureCollection } from "@turf/helpers";

export const plotsToGeoJSON = (multipolygons: string[] = []) => {
  // const geometries: Geometry[] = geometryCollection
  //   ? JSON.parse(geometryCollection).geometries
  //   : [];
  // plot.multipolygon is stored as stringified json
  return featureCollection(
    multipolygons.map((multipolygon) => feature(JSON.parse(multipolygon)))
  );
};
