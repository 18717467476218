import { SvgIconProps } from "@material-ui/core";
import { SvgIcon } from "components";

export default function Calendar(props: SvgIconProps) {
  const { style, ...restProps } = props;
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      style={{
        stroke: "currentColor",
        ...style,
        fill: "none",
      }}
      {...restProps}
    >
      <path
        d="M5.33333 1.3335V3.3335M10.6667 1.3335V3.3335M2.33333 6.06016H13.6667M14 5.66683V11.3335C14 13.3335 13 14.6668 10.6667 14.6668H5.33333C3 14.6668 2 13.3335 2 11.3335V5.66683C2 3.66683 3 2.3335 5.33333 2.3335H10.6667C13 2.3335 14 3.66683 14 5.66683Z"
        strokeWidth={1}
      />
    </SvgIcon>
  );
}
