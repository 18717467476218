import { Button, LoadingScreen, Login } from "components";
import { isSignInWithEmailLink } from "firebase/auth";
import { auth } from "firebaseInit";
import i18n from "i18n";
import { useSnackbar } from "notistack";
import queryClient from "queryClient";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  getCustomer,
  getUserPreferencesForUser,
  getUserProfileForUser,
  signInWithMagicLink,
} from "services";
import { getShareCode } from "services/firestore";
import {
  Customer,
  User,
  UserPreferences,
  UserProfile,
} from "../../../typedefs";

interface Props {
  children: ReactNode;
}

interface AuthContextValues {
  user?: User;
  userProfile?: UserProfile;
  customer?: Customer;
  userPreferences?: UserPreferences;
  signOut?: () => void;
}

export const AuthContext = createContext<AuthContextValues>(
  {} as AuthContextValues
);

const getAnonymousAuthContext = async (customerId: string) => {
  const email = "anon@anonymous.com";
  const user = {
    isAnonymous: true,
    email,
    uid: "",
    displayName: "Anonymous",
  };
  const customer = await queryClient.fetchQuery(["customers", customerId], () =>
    getCustomer(customerId || "")
  );
  const userProfile = {
    customer: customerId,
  };

  const userPreferences = {
    language: customer.defaultLanguage,
    temperatureUnit: customer.temperatureUnit,
    email,
  };
  return { user, userProfile, customer, userPreferences };
};

function AuthProvider({ children }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [authContext, setAuthContext] = useState<AuthContextValues>({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const signOut = () => {
    localStorage.removeItem("shareCode");
    localStorage.removeItem("impersonatedCustomer");
    auth.signOut();
    setAuthContext({});
  };

  const signInMagicLink = useCallback(() => {
    signInWithMagicLink().catch(() => {
      enqueueSnackbar(
        t("Login failed 🍂 Try again or contact with your administrator"),
        { variant: "error" }
      );
      setLoading(false);
    });
    // remove query params
    history.replace(history.location.pathname);
  }, [enqueueSnackbar, history, t]);

  const signInShareCode = useCallback(
    (shareCode: string) => {
      getShareCode(shareCode)
        .then(async (shareCodeObject) => {
          localStorage.setItem("shareCode", shareCode);
          history.replace(history.location.pathname);
          const anonymousUserContext = await getAnonymousAuthContext(
            shareCodeObject.customer
          );
          setAuthContext({ ...anonymousUserContext, signOut });
        })
        .catch((e) =>
          enqueueSnackbar(t("Invalid share code"), { variant: "error" })
        )
        .finally(() => setLoading(false));
    },
    [history, t, enqueueSnackbar]
  );

  const getImpersonatedCustomerId = useCallback(
    (userProfile: UserProfile) => {
      const queryParams = new URLSearchParams(location.search);

      const impersonatedCustomerId =
        queryParams.get("customer") ||
        localStorage.getItem("impersonatedCustomer");

      if (!impersonatedCustomerId) return userProfile.customer;

      if (userProfile.isInternal) {
        localStorage.setItem("impersonatedCustomer", impersonatedCustomerId);
        history.replace(history.location.pathname);
        return impersonatedCustomerId;
      } else {
        enqueueSnackbar(
          t(
            "You are trying to impersonate a customer, but are not logged in as an internal user. Please login as a different user and try again."
          ),
          { variant: "error" }
        );
        return userProfile.customer;
      }
    },
    [t, enqueueSnackbar, history, location.search]
  );

  useEffect(() => {
    setLoading(true);
    const isMagicLink = isSignInWithEmailLink(auth, window.location.href);
    if (isMagicLink) {
      signInMagicLink();
    }

    const queryParams = new URLSearchParams(location.search);
    const shareCode =
      queryParams.get("shareCode") || localStorage.getItem("shareCode");
    if (shareCode) {
      signInShareCode(shareCode);
    }

    return auth.onAuthStateChanged(async () => {
      const user = auth.currentUser;
      if (!user) {
        setAuthContext({});
        // If we're loading a magic link auth.currentUser will initially be reported as null,
        // but we don't want to remove the loading overlay until login succeeded or failed
        !isMagicLink && setLoading(false);
        return;
      }

      try {
        setLoading(true);
        // we use retry because if the user logs in for the first time,
        // it might take a while before the userProfile is created by the background job.
        const userProfile = await queryClient.fetchQuery(
          ["userProfiles", user?.uid],
          () => getUserProfileForUser(user.uid),
          { retry: 3 }
        );

        const customerId = getImpersonatedCustomerId(userProfile);

        let customer;
        try {
          customer = await queryClient.fetchQuery(
            ["customers", customerId],
            () => getCustomer(customerId)
          );
        } catch (e) {
          enqueueSnackbar(t("Customer not found."), { variant: "error" });
          signOut();
          return;
        }

        const userPreferences = await queryClient.fetchQuery(
          ["userPreferences", user?.uid],
          () => getUserPreferencesForUser(user.uid),
          { retry: 3 }
        );

        // Show snackbar if impersonating
        if (userProfile.customer !== customerId) {
          const resetImpersonation = () => {
            localStorage.setItem("impersonatedCustomer", "");
            window.location.reload();
          };
          const undoButton = (
            <Button onClick={resetImpersonation}>{t("Undo")}</Button>
          );
          enqueueSnackbar(
            t(
              `You are logged in as if you were a user from customer with id ${customer.name}`
            ),
            {
              preventDuplicate: true,
              variant: "warning",
              autoHideDuration: null,
              action: undoButton,
            }
          );
        }

        if (i18n.language !== userPreferences.language) {
          i18n.changeLanguage(userPreferences.language);
        }

        setAuthContext({
          user,
          userProfile,
          userPreferences,
          customer,
          signOut,
        });
      } finally {
        setLoading(false);
      }
    });
  }, [
    getImpersonatedCustomerId,
    history,
    enqueueSnackbar,
    signInMagicLink,
    signInShareCode,
    t,
    location.search,
  ]);

  return (
    <AuthContext.Provider value={authContext}>
      {loading ? (
        <LoadingScreen />
      ) : authContext.customer ? (
        children
      ) : (
        <Login />
      )}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
