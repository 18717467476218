import { makeStyles } from "components";
import ReactPlayer from "react-player/lazy";
import { Story } from "../../../../../typedefs";

interface VideoSectionProps {
  story: Story;
}

const useStyles = makeStyles((theme) => ({
  root: { display: "block", width: "100%" },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: "3rem",
  },
  video: {
    width: "100%",
    borderRadius: 6,
    overflow: "hidden",
  },
}));

export default function VideoSection({ story }: VideoSectionProps) {
  const classes = useStyles();

  const media = story.media[0];
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.video}>
          <ReactPlayer
            url={media.videoUrl}
            width="100%"
            playing={true}
            muted={true}
            controls={true}
          />
        </div>
      </div>
    </div>
  );
}
