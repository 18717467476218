import {
  DesktopMenu,
  Grid,
  Hidden,
  makeStyles,
  Map,
  MobileMenu,
  Sidebar,
  TopBar,
} from "components";
import React, { useEffect, useRef } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { routes } from "routes";

const menuWidth = 80;
const useStyles = makeStyles((theme) => ({
  root: {},
  sidebarWrapper: {
    display: "flex",
  },
  sidebar: {
    [theme.breakpoints.up("md")]: {
      height: "100vh",
      overflow: "auto",
    },
    transition: "all 0.5s",
  },
  desktopMenu: { position: "fixed", width: menuWidth },
  desktopSidebar: {
    width: `calc(100% - ${menuWidth}px)`,
    marginLeft: menuWidth,
  },
  map: {
    height: "50vh",
    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },
  },
}));

function App() {
  const classes = useStyles();
  const location = useLocation();
  const wideSidebar = Boolean(
    matchPath(location.pathname, { path: routes.storyDetail, exact: true })
  );
  const sideBarEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    sideBarEl?.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <Grid container alignContent="flex-start">
        <Grid
          item
          xs={12}
          sm={12}
          md={wideSidebar ? 10 : 8}
          lg={wideSidebar ? 8 : 6}
          className={classes.sidebar}
          ref={sideBarEl}
        >
          <div className={classes.sidebarWrapper}>
            <Hidden mdUp>
              <TopBar />
            </Hidden>
            <Hidden smDown>
              <div className={classes.desktopMenu}>
                <DesktopMenu />
              </div>
              <div className={classes.desktopSidebar}>
                <Sidebar />
              </div>
            </Hidden>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md lg xl className={classes.map}>
          <Map />
        </Grid>
        <Hidden mdUp>
          <Grid item xs={12}>
            <Sidebar />
          </Grid>
          <MobileMenu />
        </Hidden>
      </Grid>
    </div>
  );
}

export default App;
