export const routes = {
  home: "/",
  plantingDetail: "/planting/:plantingId",
  plantingTreesDetail: "/planting/:plantingId/trees",
  storyDetail: "/planting/:plantingId/stories/:storyId",
  userProfile: "/userProfile",
  shareLinks: "/shareLinks",
  apiTokens: "/apiTokens",
  plantings: "/plantings",
  stories: "/stories",
};
