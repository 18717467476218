import { makeStyles, Toolbar } from "components";
import React from "react";
import { useHistory } from "react-router-dom";
import { routes } from "routes";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    background: theme.palette.primary.light,
    width: "100%",
    position: "fixed",
    zIndex: 0,
  },
  logo: {
    height: 40,
    cursor: "pointer",
  },
}));

export default function TopBar() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Toolbar className={classes.root}>
        <img
          src={process.env.PUBLIC_URL + "/logo_landlifecompany.png"}
          alt="Land Life Company logo"
          className={classes.logo}
          onClick={() => history.push(routes.home)}
        />
      </Toolbar>
    </>
  );
}
