import { IconButton, makeStyles, SettingsMenu, Toolbar } from "components";
import { Home, Pin, Settings, Story } from "icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "routes";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between",
    background: theme.palette.primary.light,
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 1,
    padding: `0 ${theme.spacing(4)}px`,
    color: theme.palette.common.gray,
  },
}));

export default function MobileMenu() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Toolbar className={classes.root}>
        <IconButton
          color="inherit"
          title="Home"
          onClick={() => history.push(routes.home)}
        >
          <Home />
        </IconButton>
        <IconButton
          color="inherit"
          title="Plantings"
          onClick={() => history.push(routes.plantings)}
        >
          <Pin />
        </IconButton>
        <IconButton
          color="inherit"
          title="Stories"
          onClick={() => history.push(routes.stories)}
        >
          <Story />
        </IconButton>
        <>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
          >
            <Settings />
          </IconButton>
          <SettingsMenu onClose={handleClose} anchorEl={anchorEl} />
        </>
      </Toolbar>
    </>
  );
}
