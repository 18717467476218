import { SvgIconProps } from "@material-ui/core";
import { SvgIcon } from "components";

export default function PushPin(props: SvgIconProps) {
  const { style, ...restProps } = props;
  return (
    <SvgIcon
      viewBox="0 0 32 32"
      style={{
        stroke: "none",
        ...style,
        fill: "currentColor",
      }}
      {...restProps}
    >
      <path
        d="m 19.629127,0.32807957 c -0.437618,-0.43743943 -1.149564,-0.43743943 -1.587182,0 l -4.74195,4.74001533 c -0.437618,0.4374394 -0.437618,1.1490946 0,1.586534 L 14.364648,7.7188472 11.732408,10.350013 7.5848344,9.8929865 C 7.3888861,9.8733996 7.1929377,9.8603417 7.0035209,9.8603417 c -1.4173598,0 -2.7563402,0.5549603 -3.7556768,1.5538893 l -1.3585753,1.358021 c -0.437618,0.437439 -0.437618,1.149095 0,1.586534 l 7.086799,7.083907 -8.6478543,8.644326 c -0.437618,0.437439 -0.437618,1.149094 0,1.586534 C 0.5437567,31.889008 0.83114762,32 1.1185386,32 c 0.2873909,0 0.5747818,-0.110992 0.790325,-0.326447 l 8.6543854,-8.650855 7.086799,7.083907 c 0.215544,0.215456 0.502935,0.326448 0.790326,0.326448 0.28739,0 0.574781,-0.110992 0.790325,-0.326448 l 1.358575,-1.35802 c 1.149564,-1.149095 1.704751,-2.7291 1.521865,-4.335221 l -0.457212,-4.145881 2.632239,-2.631166 1.058121,1.05769 c 0.437618,0.437439 1.149564,0.437439 1.587182,0 l 4.74195,-4.740016 C 31.882431,13.745065 32,13.457791 32,13.163989 32,12.870186 31.882431,12.582913 31.673419,12.373986 Z M 26.147676,16.323999 25.089555,15.259781 c -0.418024,-0.417853 -1.162627,-0.417853 -1.587182,0 l -3.801398,3.806376 c -0.24167,0.241571 -0.359239,0.574547 -0.320049,0.914052 l 0.515997,4.674726 c 0.104506,0.933639 -0.215543,1.847692 -0.881768,2.507115 l -0.56825,0.568019 -14.1801295,-14.174343 0.5682503,-0.56149 c 0.6596928,-0.659423 1.5806501,-0.979342 2.508139,-0.881408 l 4.6831662,0.515787 c 0.339643,0.03917 0.672756,-0.07835 0.914425,-0.319918 l 3.801398,-3.7998472 c 0.437618,-0.4374394 0.437618,-1.1490946 0,-1.5865341 L 15.677502,5.8580974 18.83227,2.7046162 29.302444,13.170518 Z"
        id="path824"
      />
    </SvgIcon>
  );
}
