import { SvgIconProps } from "@material-ui/core";
import { SvgIcon } from "components";

export default function Video(props: SvgIconProps) {
  const { style, ...restProps } = props;
  return (
    <SvgIcon
      viewBox="0 0 26 17"
      style={{
        stroke: "currentColor",
        ...style,
        fill: "none",
      }}
      {...restProps}
    >
      <path
        strokeWidth={1.5}
        d="M13.8167 1H4.01667C2.21254 1 0.75 2.46254 0.75 4.26667V12.4333C0.75 14.2375 2.21254 15.7 4.01667 15.7H13.8167C15.6208 15.7 17.0833 14.2375 17.0833 12.4333V4.26667C17.0833 2.46254 15.6208 1 13.8167 1Z"
      />
      <path
        strokeWidth={1.5}
        d="M20.35 11.6167L24.0685 13.4754C24.193 13.5376 24.3314 13.5669 24.4704 13.5606C24.6094 13.5542 24.7445 13.5125 24.8629 13.4393C24.9812 13.3661 25.0789 13.2639 25.1467 13.1423C25.2144 13.0208 25.25 12.8839 25.25 12.7448V3.95416C25.2501 3.81498 25.2146 3.67809 25.1468 3.55651C25.0791 3.43493 24.9813 3.33271 24.8629 3.25958C24.7445 3.18646 24.6093 3.14485 24.4703 3.13873C24.3312 3.13261 24.1929 3.16217 24.0685 3.22461L20.35 5.08334"
      />
    </SvgIcon>
  );
}
