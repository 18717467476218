import { useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "components";
import "leaflet/dist/leaflet.css";
import { MapContainer as LeafletMapContainer, TileLayer } from "react-leaflet";
import { matchPath, useLocation } from "react-router-dom";
import { routes } from "routes";
import { Legend } from "./components";
import PlantingLegend from "./components/PlantingLegend";
import MapContent from "./MapContent";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZGVsYnJvbnNraWkiLCJhIjoiY2t1bWx5NXFsMGt2djJucGZrMXc4MTJuayJ9.nRK5lBseKShfZTy3qITCNg";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      height: "calc(50vh - 56px + 16px)",
      marginTop: 56,
    },
  },
  legend: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  },
}));

export default function Map() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
  const location = useLocation();
  const isPlantingDetail = Boolean(
    matchPath(location.pathname, { path: routes.plantingDetail })
  );
  return (
    <LeafletMapContainer
      center={[0, 0]}
      zoom={2}
      className={classes.mapContainer}
      zoomControl={!matches}
      attributionControl={false}
    >
      <TileLayer
        url={`https://api.mapbox.com/styles/v1/ggalobardes/ckuxww8vzabyk17mrlao0jjf3/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}`}
      />
      <MapContent />
      {isPlantingDetail ? (
        <PlantingLegend className={classes.legend} />
      ) : (
        <Legend className={classes.legend} />
      )}
    </LeafletMapContainer>
  );
}
