import { useTheme } from "@material-ui/core";
import { Grid, KeyNumber, makeStyles, Typography } from "components";
import { usePlanting } from "hooks";
import { CO2, Globe, Tree } from "icons";
import { AuthContext } from "providers";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { FilterList, OrderingList, queryFirestoreDocs } from "services";
import { formatNumber, getTotalNumbers } from "utils";
import { Measurement } from "../../../../typedefs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  keyNumber: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const useData = () => {
  const { plantingId } = useParams<{ plantingId: string }>();
  const planting = usePlanting(plantingId);

  const data = getTotalNumbers(planting?.allocations || []);

  interface ResultItem {
    val: number;
    allocationNumber?: number;
    plantingNumber?: number;
  }

  const result: {
    area?: ResultItem;
    carbonReduction?: ResultItem;
    treeCount?: ResultItem;
  } = {};

  (Object.keys(data) as Array<keyof typeof data>).forEach((key) => {
    if (!data[key] && !planting?.[key]) {
      return;
    }

    result[key] = {
      val: data[key] || planting?.[key] || 0,
      plantingNumber: planting?.[key],
      allocationNumber: data[key],
    };
  });
  return result;
};

const useMeasurements = () => {
  const { plantingId } = useParams<{ plantingId: string }>();
  const path: [string] = ["measurements"];
  const filters: FilterList = [["planting", "==", plantingId]];
  const order: OrderingList = [["date", "desc"]];
  const data = useQuery([...path, ...filters.flat()], () =>
    queryFirestoreDocs<Measurement>(path, filters, order)
  );

  const measurements = data.data || [];

  const survivalRate = measurements.find(
    (measurement) => measurement.code === "survival_rate"
  );
  const height = measurements.find(
    (measurement) => measurement.code === "height"
  );
  const vigor = measurements.find(
    (measurement) => measurement.code === "vigor"
  );

  return {
    survivalRate: survivalRate && survivalRate.value * 100,
    height: height?.value,
    vigor: vigor && Math.round(vigor.value * (10 / 3) * 100) / 100,
  };
};

export default function PlantingSummary() {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const data = useData();
  const measurements = useMeasurements();
  const { customer } = useContext(AuthContext);

  return (
    <div className={classes.root}>
      <Typography variant="h4">{t("Your impact in numbers")}</Typography>
      <Grid container spacing={2}>
        {data.treeCount && (
          <Grid item sm={4} xs={12}>
            <KeyNumber
              number={data.treeCount.val}
              icon={<Tree />}
              color={theme.palette.common.Olivine}
              description={t("Planted trees")}
              tooltip={
                data.treeCount.allocationNumber
                  ? t(
                      "{{allocationTreeCount}} trees assigned to you on this site. Land Life planted a total of {{plantingTreeCount}} trees on this site.",
                      {
                        allocationTreeCount: formatNumber(
                          data.treeCount.allocationNumber || 0,
                          "standard"
                        ),
                        plantingTreeCount: formatNumber(
                          data.treeCount.plantingNumber || 0,
                          "standard"
                        ),
                      }
                    )
                  : t("Total number of planted trees at this project")
              }
            />
          </Grid>
        )}

        {data.carbonReduction && customer?.showCO2Stats && (
          <Grid item sm={4} xs={12}>
            <KeyNumber
              icon={<CO2 />}
              color={theme.palette.common.Almond}
              number={data.carbonReduction.val}
              description={t("tCO2 to be removed")}
              tooltip={t(
                "Number of tons of CO2 projected to be removed at this project over 40 years. "
              )}
            />
          </Grid>
        )}

        {data.area && (
          <Grid item sm={4} xs={12}>
            <KeyNumber
              icon={<Globe />}
              color={theme.palette.common.LightBlue}
              number={data.area.val}
              description={t("Land restored")}
              suffix={t("ha")}
              tooltip={
                data.area.allocationNumber
                  ? t(
                      "{{allocationArea}} hectares assigned to you. Land life restored {{plantingArea}} hectares on this site.",
                      {
                        allocationArea: new Intl.NumberFormat().format(
                          data.area.allocationNumber || 0
                        ),
                        plantingArea: new Intl.NumberFormat().format(
                          data.area.plantingNumber || 0
                        ),
                      }
                    )
                  : t("Total area of restored land")
              }
            />
          </Grid>
        )}

        {Boolean(measurements.height) && (
          <Grid item sm={4} xs={12}>
            <KeyNumber
              number={measurements.height}
              suffix={t("cm")}
              description={t("Average height")}
            />
          </Grid>
        )}
        {Boolean(measurements.survivalRate) && (
          <Grid item sm={4} xs={12}>
            <KeyNumber
              number={measurements.survivalRate}
              suffix="%"
              description={t("Survival rate")}
            />
          </Grid>
        )}
        {Boolean(measurements.vigor) && (
          <Grid item sm={4} xs={12}>
            <KeyNumber
              number={measurements.vigor}
              suffix="/10"
              description={t("Vigor")}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
