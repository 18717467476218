import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "components";
import { useApiTokens } from "hooks";
import { Delete, Share } from "icons";
import { useSnackbar } from "notistack";
import { AuthContext } from "providers";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { createApiToken, deleteApiToken } from "services/firestore";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

export default function ApiTokens() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const { apiTokens, refetch } = useApiTokens();
  const { customer } = useContext(AuthContext);
  const initialApiToken = {
    label: "",
    customer: customer?.id || "",
    token: "",
  };
  const [apiToken, setApiToken] = useState(initialApiToken);

  return (
    <div className={classes.root}>
      <Typography variant="h4">{t("Managing API Tokens")}</Typography>
      <Typography variant="body1">
        {t("Use API tokens to access dashboard data through our API.")}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Label")}</TableCell>
              <TableCell>{t("API token")}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiTokens.map((apiToken) => {
              return (
                <TableRow key={apiToken.id}>
                  <TableCell component="th" scope="row">
                    {apiToken.label || "-"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {apiToken.id}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={async () => {
                        await deleteApiToken(apiToken.id);
                        refetch();
                        enqueueSnackbar(t("API token deleted"), {
                          variant: "success",
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                    <IconButton
                      aria-label="copy"
                      size="small"
                      onClick={async () => {
                        await navigator.clipboard.writeText(apiToken.id);
                        enqueueSnackbar(t("API token copied to clipboard!"));
                      }}
                    >
                      <Share />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TextField
        InputLabelProps={{ shrink: true }}
        placeholder={t("Label")}
        fullWidth
        onChange={(e) => setApiToken({ ...apiToken, label: e.target.value })}
        value={apiToken.label}
        variant="outlined"
        margin="normal"
      />
      <Button
        onClick={async () => {
          await createApiToken(apiToken);
          setApiToken(initialApiToken);
          refetch();
          enqueueSnackbar(t("API token added"), {
            variant: "success",
          });
        }}
        variant="contained"
        color="primary"
      >
        {t("Add API token")}
      </Button>
    </div>
  );
}
