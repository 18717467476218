import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { usePlantings } from "./usePlantings";

export const usePlanting = (plantingId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { plantings, isLoading } = usePlantings();
  const { t } = useTranslation();

  const planting = plantings.find((p) => p.id === plantingId);
  if (!planting && !isLoading) {
    enqueueSnackbar(t("Planting not found :("), {
      preventDuplicate: true,
      variant: "error",
      key: `planting_${plantingId}`,
    });
    return;
  }

  return planting;
};
