import { logEvent } from "@firebase/analytics";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CssBaseline, ThemeProvider } from "components";
import { createBrowserHistory } from "history";
import { SnackbarProvider } from "notistack";
import { AuthProvider, StateProvider } from "providers";
import queryClient from "queryClient";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { Router } from "react-router-dom";
import theme from "theme";
import App from "./App";
import "./firebaseInit";
import { analytics } from "./firebaseInit";
import "./i18n";
import reportWebVitals from "./reportWebVitals";

const history = createBrowserHistory();

history.listen((location) =>
  logEvent(analytics, "page_view", { page_location: location.pathname })
);

Sentry.init({
  dsn: "https://9cc7d43738f745a3addaae71fb9af8be@o4503976249131008.ingest.sentry.io/4504338724487168",
  integrations: [
    new Integrations.BrowserTracing({
      // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <QueryClientProvider client={queryClient}>
          <StateProvider>
            <CssBaseline />
            <Router history={history}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </Router>
          </StateProvider>
        </QueryClientProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
