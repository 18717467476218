import { AuthContext } from "providers";
import { useContext } from "react";
import { Translation } from "../../../typedefs";

interface TranslatableObject {
  translations?: Translation[];
}

export const useTranslateObject = () => {
  const { userPreferences } = useContext(AuthContext);
  return {
    translate: <T extends TranslatableObject>(obj: T, key: keyof T) => {
      if (!obj.translations) {
        return obj[key];
      }

      return (obj.translations
        .filter(
          (translation) => translation.language === userPreferences?.language
        )
        .find((translation) => translation.fieldName === key)?.text ||
        obj[key]) as unknown as string;
    },
  };
};
