import { makeStyles, Typography } from "components";
import { Gallery } from "icons";
import { useState } from "react";
import { getVideoThumb } from "utils";
import { Story } from "../../../../../typedefs";
import { GallerySection } from "./GallerySection";
import VideoSection from "./VideoSection";

interface ImageSectionProps {
  story: Story;
}

const useStyles = makeStyles((theme) => ({
  root: { display: "block", width: "100%" },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  video: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  image: {
    maxWidth: "100%",
    height: "400px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 6,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
    padding: theme.spacing(2),
    color: "white",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "200px",
    },
  },
  downloadIcon: {
    color: theme.palette.text.primary,
    margin: theme.spacing(1),
    background: "white",
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
  },
}));

export default function ImageSection({ story }: ImageSectionProps) {
  const classes = useStyles();
  const media = story.media[0];
  const isGallery = story.media.length > 1;
  const isVideo = Boolean(media.videoUrl) && !isGallery;
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {isVideo ? (
          <div className={classes.video}>
            <VideoSection story={story} />
          </div>
        ) : (
          <div
            style={{
              backgroundImage: `url(${
                media.image || getVideoThumb(media.videoUrl)
              })`,
            }}
            onClick={() => setOpen(true)}
            className={classes.image}
          >
            <Gallery />
          </div>
        )}
        <Typography variant="caption">{media.caption}</Typography>
      </div>
      {open && (
        <GallerySection
          story={story}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  );
}
