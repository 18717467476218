import { Menu, MenuItem } from "components";
import { AuthContext } from "providers";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { routes } from "routes";

interface SettingsMenuProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

export default function SettingsMenu({ anchorEl, onClose }: SettingsMenuProps) {
  const { t } = useTranslation();
  const { userProfile, user, signOut } = useContext(AuthContext);
  const history = useHistory();
  const menuItems = [];

  if (user?.uid) {
    menuItems.push(
      <MenuItem
        key="myAccount"
        onClick={() => {
          history.push(routes.userProfile);
          onClose();
        }}
      >
        {t("My account")}
      </MenuItem>
    );
  }

  if (userProfile?.isAdmin) {
    menuItems.push(
      <MenuItem
        key="shareLinks"
        onClick={() => {
          history.push(routes.shareLinks);
          onClose();
        }}
      >
        {t("Share your impact")}
      </MenuItem>,
      <MenuItem
        key="apiTokens"
        onClick={() => {
          history.push(routes.apiTokens);
          onClose();
        }}
      >
        {t("API tokens")}
      </MenuItem>
    );
  }

  menuItems.push(
    <MenuItem
      key="logout"
      onClick={() => {
        signOut && signOut();
        onClose();
      }}
    >
      {t("Logout")}
    </MenuItem>
  );

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {menuItems}
    </Menu>
  );
}
