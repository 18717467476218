import { Grid, makeStyles, Typography } from "components";
import { useStory } from "hooks";
import { useParams } from "react-router-dom";
import { ImageSection, TextSection } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  storyWrapper: { display: "flex" },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
}));

export default function StoryDetail() {
  const classes = useStyles();
  const { storyId } = useParams<{ storyId: string }>();
  const story = useStory(storyId);

  if (!story) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        key={story.id}
        className={classes.storyWrapper}
        justifyContent="center"
      >
        <Grid item xs={12} sm={8}>
          <Typography variant="subtitle1">
            {new Intl.DateTimeFormat(navigator.language, {
              month: "short",
              day: "numeric",
              year: "numeric",
            }).format(
              story.publishedDate?.toDate() || story.dateUpdated.toDate()
            )}
          </Typography>

          <Typography variant="h1" className={classes.title}>
            {story.title}
          </Typography>
          <ImageSection story={story} />
          <TextSection story={story} />
        </Grid>
      </Grid>
    </div>
  );
}
