import { IconButton, makeStyles, Typography } from "components";
import { useTranslateObject, useTrees } from "hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    display: "inline-flex",
    overflow: "auto",
    maxWidth: "100%",
  },
  treeLink: {
    width: "100%",
    color: theme.palette.text.primary,
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    margin: theme.spacing(1),
    flex: 1,
    transition: "all 0.2s",
    "&:hover": {
      opacity: 0.8,
      transition: "all 0.2s",
    },
  },
  treeIcon: {
    borderRadius: "50%",
    width: 90,
    height: 90,
    marginBottom: theme.spacing(1),
    backgroundSize: "cover",
  },
  moreButton: {
    background: theme.palette.primary.main,
    padding: 0,
    "&:hover": {
      background: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  moreText: {
    lineHeight: "90px",
    width: 90,
    color: theme.palette.common.black,
  },
}));

export default function TreesSummary({ treeIds = [] }: { treeIds: string[] }) {
  const treesShown = 4;
  const classes = useStyles();
  const { t } = useTranslation();
  const { translate } = useTranslateObject();
  const trees = useTrees(treeIds.slice(0, treesShown));
  let { url } = useRouteMatch();

  return (
    <div className={classes.root}>
      <Typography variant="h4">{t("Trees planted")}</Typography>
      <div className={classes.container}>
        {trees.map((tree) => (
          <Link to={`${url}/trees`} className={classes.treeLink} key={tree.id}>
            <div
              style={{ backgroundImage: `url(${tree.image})` }}
              className={classes.treeIcon}
            />
            <br />
            <Typography variant="subtitle2">
              {translate(tree, "latinName")}
            </Typography>
          </Link>
        ))}
        {treeIds.length > treesShown && (
          <Link to={`${url}/trees`} className={classes.treeLink}>
            <IconButton aria-label="menu" className={classes.moreButton}>
              <Typography variant="body1" className={classes.moreText}>
                +{treeIds.length - treesShown}
              </Typography>
            </IconButton>
          </Link>
        )}
      </div>
    </div>
  );
}
