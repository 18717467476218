import { useTheme } from "@material-ui/core";
import { Grid, KeyNumber, makeStyles, Typography } from "components";
import { usePlantings } from "hooks";
import { CO2, Globe, Tree } from "icons";
import { AuthContext } from "providers";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { getTotalNumbers } from "utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

function GlobalSummary() {
  const { customer } = useContext(AuthContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const { plantings } = usePlantings();
  const numbers = getTotalNumbers(plantings.flatMap((p) => p.allocations));

  const gridWidth = customer?.showCO2Stats ? 4 : 6;
  return (
    <div className={classes.root}>
      <Typography variant="h4">{t("Overview")}</Typography>
      <Grid container spacing={2} alignContent="flex-start">
        <Grid item xs={12} sm={gridWidth}>
          <KeyNumber
            number={numbers?.treeCount || 0}
            icon={<Tree />}
            description={t("Planted trees")}
            color={theme.palette.common.Olivine}
            tooltip={t(
              "Total number of planted trees planted. This includes all of your projects."
            )}
          />
        </Grid>

        {customer?.showCO2Stats && (
          <Grid item xs={12} sm={gridWidth}>
            <KeyNumber
              number={numbers?.carbonReduction || 0}
              icon={<CO2 />}
              description={t("tCO2 to be removed")}
              color={theme.palette.common.LightBlue}
              tooltip={t(
                "Total number of tons of CO2 projected to be removed over 40 years. This includes all of your projects."
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={gridWidth}>
          <KeyNumber
            number={numbers?.area || 0}
            icon={<Globe />}
            suffix="ha"
            description={t("Land restored")}
            color={theme.palette.common.Almond}
            tooltip={t(
              "Total area of restored land assigned to you.This includes all of your projects."
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default GlobalSummary;
