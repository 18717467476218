import { useEffect } from "react";

interface TitleProps {
  text: string;
}
export default function Title({ text }: TitleProps) {
  useEffect(() => {
    document.title = text;
  }, [text]);
  return null;
}
