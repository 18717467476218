import { SvgIconProps } from "@material-ui/core";
import { SvgIcon } from "components";

export default function Story(props: SvgIconProps) {
  const { style, ...restProps } = props;
  return (
    <SvgIcon
      viewBox="0 0 30 30"
      style={{
        stroke: "currentColor",
        strokeWidth: 1.5,
        ...style,
        fill: "none",
      }}
      {...restProps}
    >
      <path d="M26.5556 23.0001H14.1112L7.00008 28.3334V23.0001H3.44453C2.97303 23.0001 2.52085 22.8128 2.18745 22.4794C1.85405 22.146 1.66675 21.6938 1.66675 21.2223V3.44453C1.66675 2.97303 1.85405 2.52085 2.18745 2.18745C2.52085 1.85405 2.97303 1.66675 3.44453 1.66675H26.5556C27.0271 1.66675 27.4793 1.85405 27.8127 2.18745C28.1461 2.52085 28.3334 2.97303 28.3334 3.44453V21.2223C28.3334 21.6938 28.1461 22.146 27.8127 22.4794C27.4793 22.8128 27.0271 23.0001 26.5556 23.0001Z" />
      <path d="M6.7334 8.66739C6.7334 9.24222 6.96175 9.79351 7.36821 10.2C7.77468 10.6064 8.32596 10.8348 8.90079 10.8348C9.47562 10.8348 10.0269 10.6064 10.4334 10.2C10.8398 9.79351 11.0682 9.24222 11.0682 8.66739C11.0682 8.09256 10.8398 7.54128 10.4334 7.13481C10.0269 6.72835 9.47562 6.5 8.90079 6.5C8.32596 6.5 7.77468 6.72835 7.36821 7.13481C6.96175 7.54128 6.7334 8.09256 6.7334 8.66739Z" />
      <path d="M24.0668 18.441L19.013 10.8638C18.9249 10.7319 18.8059 10.6235 18.6664 10.548C18.5269 10.4725 18.3711 10.4322 18.2125 10.4305C18.0539 10.4288 17.8972 10.4658 17.7561 10.5382C17.6151 10.6107 17.4938 10.7165 17.4028 10.8465L14.8077 14.5628L13.2009 13.2716C13.0954 13.1871 12.9734 13.1254 12.8428 13.0906C12.7121 13.0557 12.5756 13.0484 12.442 13.0692C12.3084 13.09 12.1805 13.1383 12.0666 13.2112C11.9527 13.284 11.8552 13.3798 11.7803 13.4924L8.46851 18.463" />
    </SvgIcon>
  );
}
