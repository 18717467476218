import { makeStyles } from "components";

interface DividerProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  container: { width: "100vw", height: "100vh", background: "white" },
  image: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },
}));

export default function LoadingScreen({ className }: DividerProps) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img
        src={process.env.PUBLIC_URL + "/loadinganim.gif"}
        alt=""
        className={classes.image}
      />
    </div>
  );
}
