import { makeStyles } from "components";
import React from "react";

interface DividerProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "rgba(32, 39, 39, 0.1)",
  },
}));

export default function Divider({ className }: DividerProps) {
  const classes = useStyles();
  return <div className={`${className} ${classes.divider}`} />;
}
