import { SvgIconProps } from "@material-ui/core";
import { SvgIcon } from "components";

export default function Tree(props: SvgIconProps) {
  const { style, ...restProps } = props;
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{
        stroke: "currentColor",
        strokeWidth: 1,
        ...style,
        fill: "none",
      }}
      {...restProps}
    >
      <path d="M4.43319 8.96541C4.43319 4.8074 7.77718 1.43666 11.9022 1.43666C16.0272 1.43666 19.3712 4.8074 19.3712 8.96541C19.3712 13.0594 16.1293 16.3903 12.0919 16.4918V11.8089L14.8737 9.13848L14.5615 8.83875L12.0919 11.2094V7.62847L14.1151 5.60537L13.8088 5.29907L11.8634 7.24449L9.87741 5.29907L9.56329 5.60678L11.6477 7.6487V13.3797L9.23063 10.8618L8.93091 11.174L11.6477 14.004V16.4899C7.64054 16.3547 4.43319 13.0376 4.43319 8.96541ZM11.6477 16.9268C7.40125 16.7913 4 13.2787 4 8.96541C4 4.56623 7.53793 1 11.9022 1C16.2665 1 19.8044 4.56623 19.8044 8.96541C19.8044 13.3007 16.3686 16.827 12.0919 16.9285V23H11.6477V16.9268Z" />
    </SvgIcon>
  );
}
