import { makeStyles, Typography } from "components";
import { CO2, Tree } from "icons";
import { formatNumber, getTotalNumbers } from "utils";
import { Allocation, Planting } from "../../../../typedefs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "stretch",
    borderRadius: 6,
    overflow: "hidden",
  },
  image: {
    width: 100,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
  },
  plantingName: {
    lineHeight: 1.25,
  },
  country: {
    // lineHeight: 1.5,
    marginBottom: theme.spacing(1),
  },
  icons: {
    display: "flex",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

function PlantingCard({
  planting,
}: {
  planting: Planting & { allocations: Allocation[] };
}) {
  const classes = useStyles();
  const allocationTotals = getTotalNumbers(planting.allocations);
  return (
    <div className={classes.root}>
      <div
        style={{ backgroundImage: `url(${planting?.image})` }}
        className={classes.image}
      />
      <div className={classes.content}>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className={classes.plantingName}
        >
          <b>{planting.name}</b>
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.country}
        >
          {planting.country}
        </Typography>
        <div className={classes.icons}>
          {planting.treeCount && (
            <Typography variant="caption" color="textSecondary">
              <div className={classes.iconWrapper}>
                <Tree className={classes.icon} />{" "}
                <span>
                  {formatNumber(
                    allocationTotals.treeCount || planting.treeCount
                  )}
                </span>
              </div>
            </Typography>
          )}
          {planting.carbonReduction && (
            <Typography variant="caption" color="textSecondary">
              <div className={classes.iconWrapper}>
                <CO2 className={classes.icon} />{" "}
                <span>
                  {formatNumber(
                    allocationTotals.carbonReduction || planting.carbonReduction
                  )}
                </span>
              </div>
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}

export default PlantingCard;
