import { SvgIconProps } from "@material-ui/core";
import { SvgIcon } from "components";

export default function Home(props: SvgIconProps) {
  const { style, ...restProps } = props;
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{
        stroke: "currentColor",
        strokeWidth: 1,
        ...style,
        fill: "none",
      }}
      {...restProps}
    >
      <path d="M3.75299 13.9441V22.1941H9.75299V16.1941C9.75299 15.7963 9.91103 15.4147 10.1923 15.1334C10.4736 14.8521 10.8552 14.6941 11.253 14.6941H12.753C13.1508 14.6941 13.5323 14.8521 13.8137 15.1334C14.095 15.4147 14.253 15.7963 14.253 16.1941V22.1941H20.253V13.9441" />
      <path d="M0.752991 12.4441L10.942 2.25511C11.0813 2.11572 11.2467 2.00514 11.4287 1.9297C11.6108 1.85426 11.8059 1.81543 12.003 1.81543C12.2001 1.81543 12.3952 1.85426 12.5772 1.9297C12.7593 2.00514 12.9247 2.11572 13.064 2.25511L23.253 12.4441" />
    </SvgIcon>
  );
}
