import { createContext, ReactNode, useState } from "react";

interface Props {
  children: ReactNode;
}

interface StateContextValues {
  setStateContextValue: (key: string, value: any) => void;
  showAssigned: boolean;
  showRemaining: boolean;
}

const initialState = {
  showAssigned: true,
  showRemaining: true,
  setStateContextValue: (key: string, value: any) => {},
};

export const StateContext = createContext<StateContextValues>(initialState);

export default function AuthProvider({ children }: Props) {
  const [stateContext, setStateContext] =
    useState<StateContextValues>(initialState);

  const setStateContextValue = (key: string, value: any) =>
    setStateContext({ ...stateContext, [key]: value });

  return (
    <StateContext.Provider value={{ ...stateContext, setStateContextValue }}>
      {children}
    </StateContext.Provider>
  );
}
