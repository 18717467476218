import { useQuery } from "react-query";
import { getFirestoreDocs } from "services/firestore";
import { TreeSpecies } from "../../../typedefs";

export const useTrees = (trees: string[]) => {
  const path: [string] = ["treeSpecies"];

  const { data } = useQuery([...path, ...trees], () =>
    getFirestoreDocs<TreeSpecies>(trees.map((tree) => [...path, tree]))
  );

  // const imageMap = {
  //   "Broad leaf": "broadleaf.svg",
  //   Shrub: "shrub.svg",
  //   Conifer: "conifer.svg",
  // };

  return (data || []).map((tree) => ({
    ...tree,
    image: tree.image || `${process.env.PUBLIC_URL}/trees.jpeg`,
  }));
};
