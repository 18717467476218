import {
  ApiTokens,
  GlobalSummary,
  LandOwnerSummary,
  makeStyles,
  PlantingDetailList,
  PlantingDetails,
  PlantingList,
  ShareCodes,
  StoriesList,
  UserProfile,
} from "components";
import CustomerDetails from "components/customerDetails/CustomerDetails";
import { useCustomerStories } from "hooks";
import { AuthContext } from "providers";
import React, { useContext } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { routes } from "routes";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    background: "white",
    borderRadius: `16px 16px 0 0`,
    paddingBottom: 56,
  },
  fade: {
    "&-enter": {
      opacity: 0,
    },
    "&-enter-active": {
      opacity: 1,
      transition: "all 300ms",
    },
    "&-appear": {
      opacity: 0,
    },
    "&-appear-active": {
      opacity: 1,
      transition: "all 300ms",
    },
    "&-exit": {
      opacity: 1,
    },
    "&-exit-active": {
      opacity: 0,
      transition: "all 300ms",
    },
  },
}));

function Sidebar() {
  const classes = useStyles();
  const { customer } = useContext(AuthContext);
  const location = useLocation();

  const customerStories = useCustomerStories();
  return (
    <div className={classes.root}>
      <div>
        <SwitchTransition mode="out-in">
          <CSSTransition
            timeout={300}
            classNames={classes.fade}
            appear
            key={location.key || location.pathname + location.search}
            className={classes.fade}
          >
            <Switch location={location}>
              <Route path={routes.plantingDetail}>
                <PlantingDetails />
              </Route>
              <Route path={routes.userProfile}>
                <UserProfile />
              </Route>
              <Route path={routes.shareLinks}>
                <ShareCodes />
              </Route>
              <Route path={routes.apiTokens}>
                <ApiTokens />
              </Route>
              <Route path={routes.plantings}>
                <PlantingDetailList />
              </Route>
              <Route path={routes.stories}>
                <StoriesList
                  titleVariant="h1"
                  showFilter={true}
                  showMoreButton={true}
                  stories={customerStories}
                />
              </Route>
              <Route>
                <div>
                  <CustomerDetails />
                  {customer?.isLandOwner ? (
                    <LandOwnerSummary />
                  ) : (
                    <GlobalSummary />
                  )}
                  <PlantingList />
                  <StoriesList stories={customerStories} />
                </div>
              </Route>
            </Switch>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
}

export default Sidebar;
