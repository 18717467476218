import {
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  updateEmail,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { auth, functions } from "firebaseInit";

export async function sendMagicLink(email: string, newUser?: boolean) {
  // TODO: email validation
  const sendMagicLink = httpsCallable(functions, "sendMagicLink");
  // TODO: is it safe to just send window.location?
  await sendMagicLink({
    email,
    url: window.location.href,
  });
  window.localStorage.setItem("emailForSignIn", email);
}

export async function signInWithMagicLink() {
  let email = window.localStorage.getItem("emailForSignIn");
  if (!email) {
    email = window.prompt("Please provide your email for confirmation") || "";
  }
  await signInWithEmailLink(auth, email, window.location.href);
  window.localStorage.removeItem("emailForSignIn");
}

export function signInWithPassword(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password);
}

export async function updateUser({
  displayName,
  password,
  email,
}: {
  displayName?: string | null;
  password?: string | null;
  email?: string | null;
}) {
  const { currentUser } = auth;
  if (!currentUser) {
    return;
  }

  // https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
  // https://firebase.google.com/docs/reference/js/v8/firebase.User#reload
  await currentUser.reload();
  if (displayName && currentUser.displayName !== displayName) {
    await updateProfile(currentUser, { displayName: displayName });
  }

  if (password) {
    await updatePassword(currentUser, password);
  }

  if (email && currentUser.email !== email) {
    await updateEmail(currentUser, email);
  }
}

export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account",
  });
  return signInWithPopup(auth, provider);
}

export async function checkIfUserExists(email: string) {
  const signInMethods = await fetchSignInMethodsForEmail(auth, email);
  return signInMethods.length > 0;
}
