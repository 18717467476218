import { makeStyles } from "components";
import { Story } from "../../../../../typedefs";

interface TextSectionProps {
  story: Story;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    marginBottom: theme.spacing(4),
    width: "100%",
    "& p, div": theme.typography.body1,
    "& h1": theme.typography.h1,
    "& h2": theme.typography.h2,
    "& h3": theme.typography.h3,
  },
  image: { maxWidth: "50%", float: "right", marginTop: theme.spacing(2) },
}));

export default function TextSection({ story }: TextSectionProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div dangerouslySetInnerHTML={{ __html: story.text }} />
    </div>
  );
}
