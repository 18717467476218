import { Grid, makeStyles, Typography } from "components";
import { useTranslateObject, useTrees } from "hooks";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  treeDetail: {
    marginBottom: theme.spacing(2),
  },
  treeText: {
    display: "flex",
    flexDirection: "column",
  },
  treeTitle: {},
  treeIcon: {
    marginRight: theme.spacing(2),
    borderRadius: "50%",
    width: "100%",
    paddingBottom: "100%",
    backgroundSize: "cover",
  },
}));

export default function TreesDetails({ treeIds = [] }: { treeIds: string[] }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { translate } = useTranslateObject();
  const trees = useTrees(treeIds);

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        {t("Planted trees")}
      </Typography>
      {trees
        .sort((a, b) => (a.latinName < b.latinName ? 0 : 1))
        .map((tree) => (
          <Grid
            key={tree.id}
            className={classes.treeDetail}
            container
            spacing={3}
          >
            <Grid item xs={3} sm={2}>
              <div
                style={{ backgroundImage: `url(${tree.image})` }}
                className={classes.treeIcon}
              />
            </Grid>
            <Grid item xs sm md lg className={classes.treeText}>
              <Typography variant="h5" className={classes.treeTitle}>
                {translate(tree, "latinName")}
              </Typography>
              <Typography variant="subtitle2">
                {translate(tree, "description")}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </div>
  );
}
