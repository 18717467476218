import { alpha } from "@material-ui/core/styles/colorManipulator";
import { makeStyles, PlantingCard } from "components";
import { usePlantings } from "hooks";
import { Tree } from "icons";
import { DivIcon, LeafletMouseEvent, Point } from "leaflet";
import { StateContext } from "providers";
import { useContext } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { generatePath, useHistory } from "react-router-dom";
import { routes } from "routes";
import theme from "theme";

const useStyles = makeStyles((theme) => ({
  marker: {
    background: "transparent",
    border: "none",
    justifyContent: "center",
    display: "flex",
  },
  count: { position: "absolute", top: 5, zIndex: 999 },
  tooltip: {
    padding: 0,
    border: 0,
    borderRadius: 6,
    "& .leaflet-popup-content": {
      margin: 0,
    },
    "& .leaflet-popup-content-wrapper": {
      padding: 0,
    },
  },
  clusterCircle: {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  clusterTooltip: {
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    fontFamily: "Avenir",
  },
  clusterTooltipIcon: {
    marginRight: 4,
    fontSize: 18,
  },
  assignedLegend: {
    borderRadius: "50%",
    width: 8,
    height: 8,
    border: "1px solid",
    background: theme.palette.common.BrightGreen,
    marginRight: 2,
    marginLeft: 6,
  },
  otherLegend: {
    borderRadius: "50%",
    width: 8,
    height: 8,
    border: "1px solid",
    marginRight: 2,
    marginLeft: 6,
  },
  plantingsCount: {
    fontFamily: "Avenir",
    borderRadius: "50%",
    width: 16,
    height: 16,
    border: `1px solid ${theme.palette.common.black}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    fontSize: 10,
    fontWeight: "bold",
    "&.cluster": {
      width: 24,
      height: 24,
    },
    "&.user": {
      backgroundColor: theme.palette.common.BrightGreen,
    },
    "&.user.offset": {
      transform: "translate(7px, 7px)",
    },
    "&.other": {
      backgroundColor: "white",
      "&.offset": { transform: "translate(-7px, -7px" },
    },
    "&.other.offset": {
      transform: "translate(-7px, -7px)",
    },
  },
}));

const formatNumber = (number: number) =>
  new Intl.NumberFormat(navigator.language, {
    notation: "compact",
  }).format(number);

function Plantings() {
  const { showAssigned, showRemaining } = useContext(StateContext);
  const classes = useStyles();
  const { plantings } = usePlantings(showAssigned, showRemaining);

  const history = useHistory();
  const getClusterSize = (treeCount: number) =>
    Math.min(100, 100 * (treeCount / 200000));

  const getPin = (
    treeCount: number,
    userPlantings: number,
    otherPlantings: number
  ) => {
    const size = getClusterSize(treeCount);
    const color =
      userPlantings > 0 ? theme.palette.common.BrightGreen : "#ffffff";
    const transparency = userPlantings > 0 ? 0.5 : 0.1;
    const isCluster = userPlantings + otherPlantings > 1;
    return new DivIcon({
      html: ReactDOMServer.renderToString(
        <div
          className={classes.clusterCircle}
          style={{
            backgroundColor: alpha(color, transparency),
            border: `1px solid ${alpha(color, 0.5)}`,
            width: size,
            height: size,
          }}
        >
          {otherPlantings > 0 && (
            <div
              className={`other ${classes.plantingsCount} ${
                userPlantings > 0 ? "offset" : ""
              } ${isCluster ? "cluster" : ""}`}
            >
              {isCluster ? otherPlantings : ""}
            </div>
          )}
          {userPlantings > 0 && (
            <div
              className={`user ${classes.plantingsCount} ${
                otherPlantings > 0 ? "offset" : ""
              } ${isCluster ? "cluster" : ""}`}
            >
              {isCluster ? userPlantings : ""}
            </div>
          )}
        </div>
      ),
      className: classes.marker,
      iconSize: [size, size],
    });
  };

  const iconCreateFunction = (cluster: any) => {
    const markers = cluster.getAllChildMarkers();

    const treeCount = markers.reduce(
      (p: number, c: any) => p + parseInt(c.options.treeCount),
      0
    );
    let userPlantings = 0;
    let otherPlantings = 0;

    markers.forEach((m: any) => {
      if (m.options.isUserPlanting) {
        userPlantings++;
      } else {
        otherPlantings++;
      }
    });

    return getPin(treeCount, userPlantings, otherPlantings);
  };

  if (plantings.length === 0) {
    return null;
  }

  return (
    <>
      <MarkerClusterGroup
        chunkedLoading={true}
        iconCreateFunction={iconCreateFunction}
        showCoverageOnHover={false}
        key={plantings.map((p) => p.id).join()}
        onMouseOver={(e: LeafletMouseEvent) => {
          const markers = e.propagatedFrom.getAllChildMarkers();
          const assignedCount = markers
            .filter((m: any) => m.options.isUserPlanting)
            .reduce((p: number, m: any) => p + m.options.treeCount, 0);
          const otherCount = markers
            .filter((m: any) => !m.options.isUserPlanting)
            .reduce((p: number, m: any) => p + m.options.treeCount, 0);

          const size = getClusterSize(assignedCount + otherCount);
          e.propagatedFrom
            .bindTooltip(
              ReactDOMServer.renderToString(
                <div className={classes.clusterTooltip}>
                  <Tree
                    fontSize="inherit"
                    className={classes.clusterTooltipIcon}
                  />
                  {otherCount > 0 && assignedCount > 0 && (
                    <div className={classes.assignedLegend}></div>
                  )}
                  {assignedCount > 0 && formatNumber(assignedCount)}
                  {assignedCount > 0 && otherCount > 0 && (
                    <div className={classes.otherLegend}></div>
                  )}
                  {otherCount > 0 && formatNumber(otherCount)}
                </div>
              ),
              {
                direction: "top",
                opacity: 1,
                offset: new Point(0, -Math.max(size / 2 - 5, 6)),
              }
            )
            .openTooltip();
        }}
        onMouseOut={(e: LeafletMouseEvent) => {
          e.propagatedFrom.unbindTooltip();
        }}
      >
        {plantings.map((planting) => (
          <Marker
            position={planting.coordinates}
            icon={getPin(
              planting.treeCount,
              planting.isUserPlanting ? 1 : 0,
              planting.isUserPlanting ? 0 : 1
            )}
            key={planting.id}
            title={planting.name}
            // slightly hacky way to pass this data to the markercluster
            // @ts-ignore
            isUserPlanting={planting.isUserPlanting}
            // @ts-ignore
            treeCount={planting.treeCount}
            eventHandlers={{
              click: () => {
                const path = generatePath(routes.plantingDetail, {
                  plantingId: planting.id,
                });
                history.push(path);
              },
            }}
          >
            <Tooltip direction="top" className={classes.tooltip} opacity={1}>
              <PlantingCard planting={planting} />
            </Tooltip>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </>
  );
}

export default Plantings;
